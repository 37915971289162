import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export let PrivateRoute =
  ({ isAuthenticated, component: Component, ...rest }) => (
    <Route render={props => (
      isAuthenticated ? (
        <Component {...props}/>
      ) : (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location },
          search: `?next=${window.location.pathname}`
        }}/>
      )
    )} {...rest} />
  );

PrivateRoute = connect(state => ({
  isAuthenticated: state.Auth.isAuthenticated
}))(PrivateRoute);
