import React from 'react';
import flexify from '../../utils/css/flexbox';
import PropTypes from 'prop-types';

/**
 * Since there are 3 different flexbox specs, we need to run the style through
 * a utility function to manage them all.  This is a helper component that
 * wraps the style with the `flexify` function.
 */
export class Flexbox extends React.Component {
  render() {
    return (
      <div
        {...this.props}
        style={flexify({
          display: 'flex',
          ...this.props.style,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
Flexbox.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
};

export { Flexbox as default };
export { Flexbox as FlexRow };

export class CenterHoriz extends React.Component {
  render() {
    return (
      <Flexbox
        {...this.props}
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          ...this.props.style,
        }}
      >
        {this.props.children}
      </Flexbox>
    );
  }
}
CenterHoriz.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
};

export class CenterVert extends React.Component {
  render() {
    return (
      <Flexbox
        {...this.props}
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          ...this.props.style,
        }}
      >
        {this.props.children}
      </Flexbox>
    );
  }
}
CenterVert.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
};

export class FlexColumn extends React.Component {
  render() {
    return (
      <Flexbox
        {...this.props}
        style={{
          flexDirection: 'column',
          ...this.props.style,
        }}
      >
        {this.props.children}
      </Flexbox>
    );
  }
}
FlexColumn.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
};
