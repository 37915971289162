import React from 'react';
import { connect } from 'react-redux';
import { EditableTextInput, EditableTextArea } from '../shared/TextInput';
import { withRouter } from 'react-router';
import { updateOrg } from '../../actions/Events';
import { DeleteIcon } from '../shared/DeleteIcon';

export let WhatsNew = ({
  title,
  condition,
  details,
  onUpdate,
  newId,
  onDelete,
}) => {
  return (
    <div
      style={{
        border: '1px solid',
        margin: 12,
        padding: 12,
        boxSizing: 'border-box',
        flex: '0 0 450px',
      }}
    >
      <div>
        {newId + 1}
        <DeleteIcon onClick={onDelete} />
      </div>

      <EditableTextInput
        label="Title"
        defaultValue={title}
        onChange={val =>
          onUpdate({
            [`whatsNew.${newId}.title`]: val,
          })
        }
      />

      <div>
        <div>Condition</div>
        <select
          value={condition || 'none'}
          onChange={e =>
            onUpdate({
              [`whatsNew.${newId}.condition`]:
                e.target.value === 'none'
                  ? global.fb.firebase.firestore.FieldValue.delete()
                  : e.target.value,
            })
          }
        >
          <option value="none">None</option>
          <option value="NOT_CLERGY">NOT_CLERGY</option>
          <option value="HAS_ANTHEM">HAS_ANTHEM</option>
          <option value="HAS_AETNA">HAS_AETNA</option>
          <option value="HAS_KAISER">HAS_KAISER</option>
          <option value="HAS_UHC">HAS_UHC</option>
          <option value="HAS_DELTA_DENTAL">HAS_DELTA_DENTAL</option>
          <option value="HAS_VSP">HAS_VSP</option>
        </select>
      </div>

      <EditableTextArea
        label="Details"
        defaultValue={details && JSON.parse(details).join('\n')}
        onChange={val =>
          onUpdate({
            [`whatsNew.${newId}.details`]: JSON.stringify(val.split('\n')),
          })
        }
      />
    </div>
  );
};

WhatsNew = withRouter(
  connect(
    (state, ownProps) => {
      return {
        title: ownProps.whatsNew.title,
        condition: ownProps.whatsNew.condition,
        details: ownProps.whatsNew.details,
        clientId: ownProps.match.params.clientId,
        eventId: ownProps.match.params.eventId,
        orgId: ownProps.match.params.orgId,
        newId: ownProps.whatsNewId,
        onDelete: ownProps.onDelete,
      };
    },
    dispatch => {
      return {
        onUpdate: (...args) => dispatch(updateOrg(...args)),
      };
    },
    (stateProps, dispatchProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        onUpdate: obj =>
          dispatchProps.onUpdate(
            stateProps.clientId,
            stateProps.eventId,
            stateProps.orgId,
            obj,
          ),
      };
    },
  )(WhatsNew),
);
