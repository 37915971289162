import { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import {
  addCarrierContent,
  updateContent,
  addCarrierVideo,
  addCarrierSideCopy,
  addCarrierSideCopyLang,
} from '../../actions/Events';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Content } from './EventCarrierContent';
import { VideoContent } from './EventCarrierVideo';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { FirebaseContext } from '../../contexts/firebase';
// import { useDrag } from 'react-dnd';
import { useSnackbar } from 'notistack';
import {
  walkObjArraysToArrays,
  walkArraysToObjectArrays,
} from '../../utils/objectArrays';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { SketchPicker } from 'react-color';
import { UploadImage } from '../shared/UploadImage';
import { OrgsProvider } from '../../contexts/Orgs';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SideCopyCard from './SideCopyCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const _EventCarrierInfo = ({
  onUpdateContent,
  isDragging,
  event,
  // events,
  // carrier,
  // eventCarrier,
  onAddCarrierContent,
  onAddCarrierVideo,
  onAddCarrierSideCopy,
  onAddCarrierSideCopyLang,
  ...props
}) => {
  const { database } = useContext(FirebaseContext);
  const history = useHistory();
  const { clientId, eventId, carrierId } = useParams();

  const [copyTo, setCopyTo] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [eventDetails, setEventDetails] = useState({});
  const [eventCarrier, setEventCarrier] = useState({});
  const [carrier, setCarrier] = useState({});
  const [events, setEvents] = useState([]);
  const [client, setClient] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  // Get client details
  useEffect(() => {
    return database.doc(`/clients/${clientId}`).onSnapshot(
      doc => {
        setClient(walkObjArraysToArrays(doc.data()));
      },
      err => {
        enqueueSnackbar('Unable to get client details.', { variant: 'error' });
        console.error(err);
      },
    );
  }, [clientId]);

  // Get client event information
  useEffect(() => {
    const eventRef = database.doc(`/clients/${clientId}/events/${eventId}`);
    return eventRef.onSnapshot(
      doc => {
        setEventDetails(walkObjArraysToArrays(doc.data()));
      },
      err => {
        enqueueSnackbar('Unable to get event details.', { variant: 'error' });
        console.error(err);
      },
    );
  }, [clientId, eventId]);

  // Get Carrier event information
  useEffect(() => {
    const path = `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`;
    return database.doc(path).onSnapshot(
      doc => {
        setEventCarrier(walkObjArraysToArrays(doc.data()));
      },
      err => {
        enqueueSnackbar('Unable to get event carrier info', {
          variant: 'error',
        });
        console.error(err);
      },
    );
  }, [carrierId, clientId, eventId]);

  // Get the base carrier details
  useEffect(() => {
    const path = `/carriers/${carrierId}`;
    return database.doc(path).onSnapshot(
      doc => {
        setCarrier(walkObjArraysToArrays(doc.data()));
      },
      err => {
        enqueueSnackbar('Unable to get event carrier info', {
          variant: 'error',
        });
        console.error(err);
      },
    );
  }, [carrierId, database, enqueueSnackbar]);

  // Get all events for a client
  useEffect(() => {
    return database.collection(`/clients/${clientId}/events`).onSnapshot(
      docs => {
        const data = docs.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setEvents(walkObjArraysToArrays(data));
      },
      err => {
        enqueueSnackbar('Unable to get events for client', {
          variant: 'error',
        });
        console.error(err);
      },
    );
  }, [clientId]);

  const handleCopy = async () => {
    console.log('copy', clientId, carrierId, eventId, copyTo);

    const from = await database
      .doc(`/clients/${clientId}/events/${eventId}/carriers/${carrierId}`)
      .get();

    console.log('FROM', from.data());
    console.log(
      'TO',
      `/clients/${clientId}/events/${copyTo}/carriers/${carrierId}`,
    );

    await database
      .doc(`/clients/${clientId}/events/${copyTo}/carriers/${carrierId}`)
      .set(from.data());
  };

  /**
   * Drag and Drop
   */
  const [sideCopy, setSideCopy] = useState([]);
  const [content, setContent] = useState([]);
  const [videoContent, setVideoContent] = useState([]);

  useEffect(() => {
    setSideCopy(
      eventCarrier?.sideCopy?.map((copy, id) => {
        return {
          id: 'side-copy-' + id,
          copy: copy,
        };
      }) || [],
    );
    console.log({ eventCarrier });

    setContent(
      eventCarrier?.content?.map((content, id) => {
        return {
          id: 'content-' + id,
          content,
        };
      }) || [],
    );

    setVideoContent(
      eventCarrier?.videoContent?.map((videoContent, id) => {
        return {
          id: 'video-content-' + id,
          videoContent,
        };
      }) || [],
    );
  }, [eventCarrier]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEndSideCopy = event => {
    const { active, over } = event;

    setSideCopy(copy => {
      const oldIndex = copy.findIndex(d => d.id === active.id);
      const newIndex = copy.findIndex(d => d.id === over.id);

      const newArray = arrayMove(sideCopy, oldIndex, newIndex);

      onUpdateContent(clientId, eventId, carrierId, {
        sideCopy: walkArraysToObjectArrays(newArray.map(d => d.sideCopy)),
      });

      return newArray;
    });
  };

  const handleDragEnd = event => {
    const { active, over } = event;
    console.log({ active, over });

    setContent(content => {
      const oldIndex = content.findIndex(c => c.id === active.id);
      const newIndex = content.findIndex(c => c.id === over.id);

      const newArray = arrayMove(content, oldIndex, newIndex);

      onUpdateContent(clientId, eventId, carrierId, {
        content: walkArraysToObjectArrays(newArray.map(c => c.content)),
      });

      return newArray;
    });
  };

  const downloadJson = async () => {
    let file;
    try {
      file = await window.showSaveFilePicker({
        suggestedName: `${eventId}.${carrierId}.json`,
      });
    } catch (e) {
      console.error(e);
    }

    const blob = new Blob(
      [JSON.stringify(walkArraysToObjectArrays(eventCarrier), null, 2)],
      {
        type: 'application/json',
      },
    );

    const ws = await file.createWritable();
    await ws.write(blob);
    await ws.close();
  };

  const uploadJson = async () => {
    let file;
    try {
      [file] = await window.showOpenFilePicker({
        types: [
          {
            description: 'JSON Files',
            accept: {
              'application/json': ['.json'],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      });
    } catch (e) {
      console.error(e);
    }

    const blob = await file.getFile();
    const text = await blob.text();

    const json = JSON.parse(text);

    await database
      .doc(`/clients/${clientId}/events/${eventId}/carriers/${carrierId}`)
      .set(walkArraysToObjectArrays(json));
  };

  return (
    <OrgsProvider>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
        }}
      >
        <Stack direction="row" sx={{ width: '100%' }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: 'baseline', flex: 1 }}
          >
            <Typography variant="h4">Event:</Typography>
            <Link to={`/clients/${clientId}/events/${eventId}`}>
              <Typography variant="h5">{eventDetails.displayName}</Typography>
            </Link>
          </Stack>
          <Stack direction="row" spacing={1}>
            <IconButton aria-label="download" onClick={downloadJson}>
              <CloudDownloadIcon />
            </IconButton>
            <IconButton aria-label="upload" onClick={uploadJson}>
              <CloudUploadIcon />
            </IconButton>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
          <Typography variant="h4">Carrier:</Typography>
          <Link to={`/carriers/${carrierId}`}>
            <Typography variant="h5">{carrier.displayName}</Typography>
          </Link>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={events.map(({ id, displayName }) => ({
              label: displayName,
              id,
            }))}
            sx={{ width: 400 }}
            renderInput={params => (
              <TextField {...params} label="Copy To Event" />
            )}
            onChange={(event, newValue) => {
              setCopyTo(newValue?.id);
            }}
          />
          <Button
            variant="contained"
            disabled={!copyTo}
            onClick={async () => {
              const path = `/clients/${clientId}/events/${copyTo}/carriers/${carrierId}`;
              await handleCopy();
              enqueueSnackbar('Copied.', { variant: 'success' });

              history.push(path);
            }}
          >
            Copy Event Info
          </Button>
        </Stack>

        <Typography variant="h4">Colors</Typography>
        <Stack spacing={2} direction="row">
          <Stack spacing={2}>
            <Typography variant="h5">Side Background</Typography>{' '}
            <SketchPicker
              color={
                eventCarrier?.colors?.background?.side ||
                carrier?.colors?.background?.side ||
                client?.colors?.background?.global
              }
              onChangeComplete={color => {
                onUpdateContent(clientId, eventId, carrierId, {
                  'colors.background.side': color.hex,
                });
              }}
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant="h5">Content</Typography>{' '}
            <SketchPicker
              color={
                eventCarrier?.colors?.background?.content ||
                carrier?.colors?.background?.content ||
                'white'
              }
              onChangeComplete={color => {
                onUpdateContent(clientId, eventId, carrierId, {
                  'colors.background.content': color.hex,
                });
              }}
            />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2}>
          <UploadImage
            title="Side Logo"
            obj={eventCarrier}
            objPath="images.logo.url"
            dest={`/clients/${clientId}/events/${eventId}/carriers/${carrierId}/images/originals/logo.{basename}.{ext}`}
            metadata={{
              resize: '250x100>',
              gsOutputPath: `/clients/${clientId}/events/${eventId}/carriers/${carrierId}/images/logo.{epoch}.{ext}`,
              firestorePath: `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
              firestoreField: 'images.logo.url',
            }}
            onUploaded={async url => {
              await database
                .doc(
                  `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
                )
                .update({ 'images.logo.url': url });
            }}
          />
        </Stack>

        {/* Side Copy */}
        <Paper elevation={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Side Copy</Typography>

            <IconButton
              aria-label="drag"
              size="small"
              color="secondary"
              onClick={event => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={showMenu}
            onClose={() => setAnchorEl(null)}
          >
            <MenuList dense>
              <MenuItem
                onClick={() => {
                  onUpdateContent(clientId, eventId, carrierId, {
                    sideCopy: walkArraysToObjectArrays([
                      ...(eventCarrier.sideCopy || []),
                      [{}],
                    ]),
                  });
                }}
              >
                Add Side Copy Section
              </MenuItem>
            </MenuList>
          </Menu>

          <Box>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEndSideCopy}
            >
              <SortableContext
                items={sideCopy}
                strategy={verticalListSortingStrategy}
              >
                {sideCopy.map((copy, c) => {
                  return <SideCopyCard key={c} idx={c} sideCopy={copy.copy} />;
                })}
              </SortableContext>
            </DndContext>
          </Box>
        </Paper>

        <Typography variant="h6">Content</Typography>
        <Box>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={content}
              strategy={verticalListSortingStrategy}
            >
              {content.map((c, i) => {
                return (
                  <Content key={i} id={i} contentId={i} content={c.content} />
                );
              })}
            </SortableContext>
          </DndContext>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => {
              onUpdateContent(clientId, eventId, carrierId, {
                content: walkArraysToObjectArrays([
                  ...(eventCarrier.content || []),
                  [{}],
                ]),
              });
            }}
          >
            Add Content
          </Button>
        </Box>

        <Typography variant="h5">Video Content</Typography>

        <Box>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            // onDragEnd={handleDragEndVideoContent}
          >
            <SortableContext
              items={videoContent}
              strategy={verticalListSortingStrategy}
            >
              <Stack spacing={2}>
                {console.log({ videoContent })}
                {videoContent.map((c, i) => {
                  return (
                    <VideoContent
                      key={i}
                      contentId={i}
                      content={c.videoContent}
                    />
                  );
                })}
              </Stack>
            </SortableContext>
          </DndContext>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => {
              onUpdateContent(clientId, eventId, carrierId, {
                videoContent: walkArraysToObjectArrays([
                  ...(eventCarrier.videoContent || []),
                  [{}],
                ]),
              });
            }}
          >
            Add Video Content
          </Button>
        </Box>
      </Paper>
    </OrgsProvider>
  );
};

export const EventCarrierInfo = connect(
  state => ({
    event: state.Events.current,
    events: state.Events.events,
    eventCarrier: state.Events.carrier,
    carrier: state.Carriers.current,
  }),
  dispatch => ({
    onAddCarrierContent: () => dispatch(addCarrierContent()),
    onAddCarrierSideCopy: () => dispatch(addCarrierSideCopy()),
    onAddCarrierSideCopyLang: (...args) =>
      dispatch(addCarrierSideCopyLang(...args)),
    onAddCarrierVideo: () => dispatch(addCarrierVideo()),
    onUpdateContent: (...args) => dispatch(updateContent(...args)),
    onUpdate: (...args) => dispatch(updateContent(...args)),
  }),
)(_EventCarrierInfo);
