import { useEffect, useState, useContext } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import { FirebaseContext } from '../../contexts/firebase';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

const langOptions = [
  { label: 'English', id: 'english' },
  { label: 'Spanish', id: 'spanish' },
];

export const SlugEditor = ({ slug, carrierId }) => {
  const { database } = useContext(FirebaseContext);

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (!slug) return;

    const languages = Object.entries(slug)
      .filter(([key, value]) => key !== 'style')
      .map(([key, value]) => {
        return { language: key, value: value.value };
      })
      .sort((a, b) => a.language.localeCompare(b.language));

    console.log({ languages });
    setLanguages(languages);
  }, [slug]);

  const updateCarrier = (path, value) => {};

  return (
    <Paper elevation={5}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="h6">Slug Line</Typography>

        <Stack direction="row" spacing={2}>
          {languages.map(({ language, value }, i) => {
            return (
              <Stack key={i} spacing={2}>
                <Box>
                  <FormControl>
                    <TextField
                      label={`Slug Line (${language})`}
                      variant="outlined"
                      fullWidth
                      placeholder="Enter the slug line"
                      defaultValue={value}
                    />
                  </FormControl>
                </Box>
              </Stack>
            );
          })}
        </Stack>

        <Box>
          <FormControl>
            <TextField
              label="Slug Style"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Enter the slug style"
              defaultValue={JSON.stringify(slug?.style, null, 2)}
            />
          </FormControl>
        </Box>

        <Typography variant="caption">
          * Edit the Slug content in Firestore for now.
        </Typography>
      </Stack>
    </Paper>
  );
};

export default SlugEditor;
