import { newErrorMsg } from './Messages';
import { store } from '../store';
import objectWalk from 'object-walk';
import _isPlainObject from 'lodash.isplainobject';

export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const getEvents = companyId => {
  return dispatch => {
    return global.fb.database
      .collection('clients')
      .doc(companyId)
      .collection('events')
      .onSnapshot(
        docs => {
          dispatch({
            type: SET_EVENT_LIST,
            events: docs.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }),
          });
        },
        err => {
          console.error(err);
        },
      );
  };
};

export const NEW_EVENT = 'NEW_EVENT';
export const newEvent = (clientId, eventId, displayName) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}`)
        .set({
          displayName,
        });

      dispatch({ type: NEW_EVENT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const UPDATE_EVENT_DETAILS = 'UPDATE_EVENT_DETAILS';
export const updateEventDetails = (clientId, eventId, obj) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}`)
        .update(obj);

      dispatch({ type: UPDATE_EVENT_DETAILS });
    } catch (e) {
      console.error(e);
    }
  };
};

export const RESET_EVENT = 'RESET_EVENT';
export const deleteEvent = (clientId, eventId, history) => {
  return async dispatch => {
    history.push(`/clients/${clientId}`);

    try {
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}`)
        .delete();
      dispatch({ type: RESET_EVENT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS';
export const getEventDetails = (clientId, eventId) => {
  return dispatch => {
    const eventRef = global.fb.database.doc(
      `/clients/${clientId}/events/${eventId}`,
    );
    return eventRef.onSnapshot(
      doc => {
        dispatch({
          type: SET_EVENT_DETAILS,
          event: doc.data(),
          clientId,
          eventId,
          eventRef,
        });
      },
      err => {
        newErrorMsg('Unable to get event details', err.stack);
        console.error(err);
      },
    );
  };
};

export const SET_EVENT_ORGS = 'SET_EVENT_ORGS';
export const getEventOrgs = (clientId, eventId) => {
  return dispatch => {
    return global.fb.database
      .doc(`/clients/${clientId}/events/${eventId}`)
      .collection('organizations')
      .onSnapshot(
        docs => {
          dispatch({
            type: SET_EVENT_ORGS,
            orgs: docs.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }),
          });
        },
        err => {
          newErrorMsg('Unable to get event organizations', err.stack);
          console.error(err);
        },
      );
  };
};

export const SET_EVENT_CARRIERS = 'SET_EVENT_CARRIERS';
export const getEventCarriers = (clientId, eventId) => {
  return dispatch => {
    return global.fb.database
      .doc(`/clients/${clientId}/events/${eventId}`)
      .collection('carriers')
      .onSnapshot(
        docs => {
          dispatch({
            type: SET_EVENT_CARRIERS,
            carriers: docs.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }),
          });
        },
        err => {
          newErrorMsg('Unable to get event carriers', err.stack);
          console.error(err);
        },
      );
  };
};

export const EVENT_ADD_CARRIER = 'EVENT_ADD_CARRIER';
export const addCarrierInfo = (clientId, eventId, carrierId) => {
  return async dispatch => {
    try {
      const path = `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`;
      await global.fb.database.doc(path).set({ carrierId });
      dispatch({ type: EVENT_ADD_CARRIER, carrierId });
    } catch (e) {
      console.error(e);
    }
  };
};

export const EVENT_ADD_ORG = 'EVENT_ADD_ORG';
export const addOrgInfo = (clientId, eventId, orgId) => {
  return async dispatch => {
    try {
      const path = `/clients/${clientId}/events/${eventId}/organizations/${orgId}`;
      await global.fb.database.doc(path).set({ orgId });
      dispatch({ type: EVENT_ADD_ORG, orgId });
    } catch (e) {
      console.error(e);
    }
  };
};

export const EVENT_DELETE_CARRIER = 'EVENT_DELETE_CARRIER';
export const deleteCarrierInfo = (clientId, eventId, carrierId) => {
  return async dispatch => {
    try {
      const path = `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`;
      await global.fb.database.doc(path).delete();
      dispatch({ type: EVENT_DELETE_CARRIER, carrierId });
    } catch (e) {
      console.error(e);
    }
  };
};

export const EVENT_DELETE_ORG = 'EVENT_DELETE_ORG';
export const deleteOrgInfo = (clientId, eventId, orgId) => {
  return async dispatch => {
    try {
      const path = `/clients/${clientId}/events/${eventId}/organizations/${orgId}`;
      await global.fb.database.doc(path).delete();
      dispatch({ type: EVENT_DELETE_ORG, orgId });
    } catch (e) {
      console.error(e);
    }
  };
};

export const SET_EVENT_CARRIER_INFO = 'SET_EVENT_CARRIER_INFO';
export const getEventCarrierInfo = (clientId, eventId, carrierId) => {
  return dispatch => {
    const path = `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`;
    return global.fb.database.doc(path).onSnapshot(
      doc => {
        dispatch({
          type: SET_EVENT_CARRIER_INFO,
          carrier: doc.data(),
        });
      },
      err => {
        newErrorMsg('Unable to get event carrier info', err.stack);
        console.error(err);
      },
    );
  };
};

export const SET_EVENT_ORG_INFO = 'SET_EVENT_ORG_INFO';
export const getEventOrgInfo = (clientId, eventId, orgId) => {
  return dispatch => {
    const path = `/clients/${clientId}/events/${eventId}/organizations/${orgId}`;
    return global.fb.database.doc(path).onSnapshot(
      doc => {
        dispatch({
          type: SET_EVENT_ORG_INFO,
          org: doc.data(),
        });
      },
      err => {
        newErrorMsg('Unable to get event org info', err.stack);
        console.error(err);
      },
    );
  };
};

export const EVENT_ADD_CARRIER_CONTENT = 'EVENT_ADD_CARRIER_CONTENT';
export const addCarrierContent = () => {
  return {
    type: EVENT_ADD_CARRIER_CONTENT,
  };
};

export const EVENT_ADD_CONTENT_MENU = 'EVENT_ADD_CONTENT_MENU';
export const addContentMenu = (contentId, languageId) => {
  return {
    type: EVENT_ADD_CONTENT_MENU,
    contentId,
    languageId,
  };
};

export const EVENT_ADD_CONTENT_LANGUAGE = 'EVENT_ADD_CONTENT_LANGUAGE';
export const addContentLanguage = contentId => {
  return {
    type: EVENT_ADD_CONTENT_LANGUAGE,
    contentId,
  };
};

export const EVENT_UPDATE_CONTENT = 'EVENT_UPDATE_CONTENT';
export const updateContent = (clientId, eventId, carrierId, obj) => {
  console.log('obj', obj);
  return async dispatch => {
    const path = `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`;
    try {
      await global.fb.database.doc(path).update(obj);
    } catch (e) {
      console.error('Error', e);
    }

    dispatch({
      type: EVENT_UPDATE_CONTENT,
      obj,
    });
  };
};

export const EVENT_ADD_CARRIER_VIDEO = 'EVENT_ADD_CARRIER_VIDEO';
export const addCarrierVideo = () => {
  return {
    type: EVENT_ADD_CARRIER_VIDEO,
  };
};

export const EVENT_ADD_CARRIER_SIDE_COPY = 'EVENT_ADD_CARRIER_SIDE_COPY';
export const addCarrierSideCopy = () => {
  return {
    type: EVENT_ADD_CARRIER_SIDE_COPY,
  };
};

export const EVENT_ADD_CARRIER_SIDE_COPY_LANG =
  'EVENT_ADD_CARRIER_SIDE_COPY_LANG';
export const addCarrierSideCopyLang = copyId => {
  return {
    type: EVENT_ADD_CARRIER_SIDE_COPY_LANG,
    copyId,
  };
};

export const EVENT_ADD_CARRIER_VIDEO_SECTION =
  'EVENT_ADD_CARRIER_VIDEO_SECTION';
export const addVideoSection = contentId => {
  return {
    type: EVENT_ADD_CARRIER_VIDEO_SECTION,
    contentId,
  };
};
export const EVENT_ADD_CARRIER_VIDEO_ITEM = 'EVENT_ADD_CARRIER_VIDEO_ITEM';
export const addSectionItem = (contentId, sectionId) => {
  return {
    type: EVENT_ADD_CARRIER_VIDEO_ITEM,
    contentId,
    sectionId,
  };
};

export const EVENT_ADD_ORG_WHATS_NEW = 'EVENT_ADD_ORG_WHATS_NEW';
export const addWhatsNew = () => {
  return {
    type: EVENT_ADD_ORG_WHATS_NEW,
  };
};

export const EVENT_ADD_ORG_OTHER_PLAN = 'EVENT_ADD_ORG_OTHER_PLAN';
export const addOtherPlan = () => {
  return {
    type: EVENT_ADD_ORG_OTHER_PLAN,
  };
};

export const EVENT_ADD_ORG_OTHER_MEDIA = 'EVENT_ADD_ORG_OTHER_MEDIA';
export const addEventOrgMedia = index => {
  return {
    type: EVENT_ADD_ORG_OTHER_MEDIA,
    index,
  };
};

export const UPDATE_EVENT_ORG = 'UPDATE_EVENT_ORG';
export const updateOrg = (clientId, eventId, orgId, obj) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
        .update(obj);

      dispatch({ type: UPDATE_EVENT_ORG });
    } catch (e) {
      console.error(e);
    }
  };
};

export const UPDATE_EVENT_INFO = 'UPDATE_EVENT_INFO';
export const updateEventInfo = (clientId, eventId, obj) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}`)
        .update(obj);

      dispatch({ type: UPDATE_EVENT_INFO });
    } catch (e) {
      console.error(e);
    }
  };
};

// eslint-disable-next-line
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

export const mapArrayToObject = arr => {
  return arr
    .map((a, i) => {
      if (Array.isArray(a)) {
        return { [i]: mapArrayToObject(a) };
      }
      return { [i]: a };
    })
    .reduce((p, c) => {
      return {
        ...p,
        ...c,
      };
    }, {});
};

export const normalizeObjIds = obj => {
  const result = Object.keys(obj)
    .reduce((p, c) => {
      p.push(obj[c]);
      return p;
    }, [])
    .reduce((p, c, i) => {
      p[i] = c;
      return p;
    }, {});
  return result;
};

export const processObject = objIn => {
  return normalizeObjIds(
    objectWalk(objIn, (val, prop, obj) => {
      // delete undefined values
      if (val === undefined) {
        delete obj[prop];
      }
      // normalize id's of `object arrays`
      if (_isPlainObject(val)) {
        obj[prop] = normalizeObjIds(val);
      }
      // Compact arrays
      if (Array.isArray(val)) {
        obj[prop] = val.reduce((p, c) => {
          if (!c) {
            return null;
          }
          p.push(c);
          return p;
        }, []);
      }
    }),
  );
};

export const EVENT_CONTENT_REORDER = 'EVENT_CONTENT_REORDER';
export const orderUp = index => {
  const events = store.getState().Events;
  const eventRef = events.current.eventRef;

  const list = events.carrier.content;
  list.move(index, index - 1);

  let fbaseFriendly = processObject(mapArrayToObject(list));

  return async dispatch => {
    try {
      await eventRef
        .collection('carriers')
        .doc(events.carrier.carrierId)
        .update({
          content: fbaseFriendly,
        });

      dispatch({ type: EVENT_CONTENT_REORDER, list });
    } catch (e) {
      console.error(e);
    }
  };
};

export const orderDown = index => {
  const events = store.getState().Events;
  const eventRef = events.current.eventRef;

  const list = events.carrier.content;
  list.move(index, index + 1);

  let fbaseFriendly = processObject(mapArrayToObject(list));

  return async dispatch => {
    try {
      await eventRef
        .collection('carriers')
        .doc(events.carrier.carrierId)
        .update({
          content: fbaseFriendly,
        });

      dispatch({ type: EVENT_CONTENT_REORDER, list });
    } catch (e) {
      console.error(e);
    }
  };
};
