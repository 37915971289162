import { newErrorMsg } from './Messages';

export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const getClientList = () => {
  return dispatch => {
    return global.fb.database.collection('clients').onSnapshot(
      docs => {
        dispatch({
          type: SET_CLIENT_LIST,
          clients: docs.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
        });
      },
      err => {
        newErrorMsg('Unable to get the client list', err.stack);
        console.error(err);
      },
    );
  };
};

export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS';
export const getClientDetails = clientId => {
  return dispatch => {
    return global.fb.database
      .collection('clients')
      .doc(clientId)
      .onSnapshot(
        doc => {
          dispatch({
            type: SET_CLIENT_DETAILS,
            client: {
              ...doc.data(),
              id: clientId,
            },
          });
        },
        err => {
          newErrorMsg('Unable to get client info', err.stack);
          console.error(err);
        },
      );
  };
};

export const NEW_CLIENT = 'NEW_CLIENT';
export const newClient = (clientId, displayName) => {
  return async dispatch => {
    try {
      await global.fb.database.collection('clients').doc(clientId).set({
        displayName,
      });

      dispatch({ type: NEW_CLIENT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const RESET_CLIENT = 'RESET_CLIENT';
export const deleteClient = (clientId, history) => {
  return async dispatch => {
    try {
      history.push('/clients');
      await global.fb.database.collection('clients').doc(clientId).delete();
      dispatch({ type: RESET_CLIENT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const NEW_CLIENT_CONTACT = 'NEW_CLIENT_CONTACT';
export const newContact = () => {
  return { type: NEW_CLIENT_CONTACT };
};

export const UPDATE_CLIENT_CONTACT = 'UPDATE_CLIENT_CONTACT';
export const updateContact = (clientId, index, field, val) => {
  return async dispatch => {
    try {
      await global.fb.database.doc(`/clients/${clientId}`).update({
        [`contacts.${index}.${field}`]: val,
      });

      dispatch({ type: UPDATE_CLIENT_CONTACT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const DELETE_CLIENT_CONTACT = 'DELETE_CLIENT_CONTACT';
export const deleteContact = (clientId, index) => {
  return async dispatch => {
    try {
      await global.fb.database.doc(`/clients/${clientId}`).update({
        [`contacts.${index}`]: global.fb.firebase.firestore.FieldValue.delete(),
      });

      dispatch({ type: DELETE_CLIENT_CONTACT });
    } catch (e) {
      console.error(e);
    }
  };
};
export const UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS';
export const updateClientDetails = (clientId, obj) => {
  return async dispatch => {
    try {
      await global.fb.database.collection('clients').doc(clientId).update(obj);

      dispatch({ type: UPDATE_CLIENT_DETAILS });
    } catch (e) {
      console.error(e);
    }
  };
};
