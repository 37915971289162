import React from 'react';
import { Flexbox } from '../shared/Flexbox';
import { UploadFile } from '../shared/UploadFile';
import _has from 'lodash.has';
import _get from 'lodash.get';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export const UploadImage = ({
  title,
  obj,
  objPath,
  dest,
  metadata,
  onUploaded = () => {},
}) => (
  <Paper>
    <Typography variant="h4">{title}</Typography>

    {!_has(obj, objPath) ? (
      <div>No image found</div>
    ) : (
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#d1d1d1',
          minHeight: 150,
          minWidth: 150,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            maxWidth: '100vw',
          }}
          src={_get(obj, objPath)}
          alt="Upload"
        />
      </Box>
    )}

    <UploadFile dest={dest} metadata={metadata} onUploaded={onUploaded} />
  </Paper>
);
