import React, { useEffect } from 'react';
import { EventList } from '../events/EventList';
import { OrgList } from '../orgs/OrgList';
import { connect } from 'react-redux';
import { DeleteIcon } from '../shared/DeleteIcon';
import { Button } from '../shared/Button';
import { EditableTextInput, EditableTextArea } from '../shared/TextInput';
import { ms } from '../../constants';
import {
  getClientDetails,
  updateClientDetails,
  newContact,
  updateContact,
  deleteContact,
  deleteClient,
} from '../../actions/Clients';
// import { UploadImage } from '../shared/UploadImage';
import { UploadImage } from '../shared/UploadImage';
import _has from 'lodash.has';
import { useHistory, useParams } from 'react-router';
import { OrgsProvider } from '../../contexts/Orgs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

export let ClientDetails = ({
  client,
  onGetClientDetails,
  onUpdateDetails,
  onUpdateContact,
  ...props
}) => {
  const history = useHistory();
  const { clientId } = useParams();

  useEffect(() => {
    const unsub = onGetClientDetails(props.match.params.clientId);

    return unsub;
    // eslint-disable-next-line
  }, []);

  const _onUpdate = obj => {
    return onUpdateDetails(props.match.params.clientId, obj);
  };

  // componentWillReceiveProps(nextProps) {
  //   this.contacts = nextProps.client.contacts;
  // }

  const _onUpdateContact = (id, field, val) => {
    return onUpdateContact(props.match.params.clientId, id, field, val);
  };

  return (
    <OrgsProvider clientId={clientId}>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
        }}
      >
        <Stack direction="row" spacing={2}>
          <Typography variant="h2">Client:</Typography>
          <Typography variant="h2">{client.displayName}</Typography>
          <DeleteIcon onClick={() => props.onDeleteClient(clientId, history)} />
        </Stack>

        <Box>
          <EditableTextInput
            label="Display Name"
            defaultValue={client.displayName}
            onChange={val => _onUpdate({ displayName: val })}
          />

          <EventList />

          <OrgList />

          <Box>
            <EditableTextInput
              label="Background Color"
              defaultValue={
                _has(client, 'colors.background.global')
                  ? client.colors.background.global
                  : null
              }
              onChange={val => _onUpdate({ 'colors.background.global': val })}
            />

            <EditableTextInput
              label="Text Color"
              defaultValue={
                _has(client, 'colors.text.global')
                  ? client.colors.text.global
                  : null
              }
              onChange={val => _onUpdate({ 'colors.text.global': val })}
            />

            <EditableTextInput
              label="Text Emphasis Color"
              defaultValue={
                _has(client, 'colors.text.emphasis')
                  ? client.colors.text.emphasis
                  : null
              }
              onChange={val => _onUpdate({ 'colors.text.emphasis': val })}
            />
          </Box>

          <Box>
            <EditableTextArea
              label="Welcome Slug"
              defaultValue={
                _has(client, 'welcome.messages.slug')
                  ? client.welcome.messages.slug
                  : null
              }
              onChange={val => _onUpdate({ 'welcome.messages.slug': val })}
            />

            <EditableTextArea
              label="Mid Year Welcome Message"
              defaultValue={
                _has(client, 'welcome.messages.midYear')
                  ? client.welcome.messages.midYear.join('\n')
                  : null
              }
              onChange={val =>
                _onUpdate({ 'welcome.messages.midYear': val.split('\n') })
              }
            />

            <EditableTextArea
              label="OE Welcome Message"
              defaultValue={
                _has(client, 'welcome.messages.oe') &&
                client.welcome.messages.oe.join('\n')
              }
              onChange={val =>
                _onUpdate({ 'welcome.messages.oe': val.split('\n') })
              }
            />

            <EditableTextArea
              label="New Hire Welcome Message"
              defaultValue={
                _has(client, 'welcome.messages.newHire') &&
                client.welcome.messages.newHire.join('\n')
              }
              onChange={val =>
                _onUpdate({ 'welcome.messages.newHire': val.split('\n') })
              }
            />
          </Box>

          <Box>
            <EditableTextArea
              label="Welcome Menu: New Hire Message"
              defaultValue={
                _has(client, 'welcome.messages.menu.newHire') &&
                client.welcome.messages.menu.newHire.join('\n')
              }
              onChange={val =>
                _onUpdate({ 'welcome.messages.menu.newHire': val.split('\n') })
              }
            />

            <EditableTextArea
              label="Welcome Menu: Mid Year Message"
              defaultValue={
                _has(client, 'welcome.messages.menu.midYear') &&
                client.welcome.messages.menu.midYear.join('\n')
              }
              onChange={val =>
                _onUpdate({ 'welcome.messages.menu.midYear': val.split('\n') })
              }
            />

            <EditableTextArea
              label="Welcome Menu: OE Message"
              defaultValue={
                _has(client, 'welcome.messages.menu.oe') &&
                client.welcome.messages.menu.oe.join('\n')
              }
              onChange={val =>
                _onUpdate({
                  'client.welcome.messages.menu.oe': val.split('\n'),
                })
              }
            />

            <EditableTextArea
              label="Welcome Menu: OE Message - No Plans"
              defaultValue={
                _has(client, 'welcome.messages.menu.oeNoPlans') &&
                client.welcome.messages.menu.oeNoPlans.join('\n')
              }
              onChange={val =>
                _onUpdate({
                  'client.welcome.messages.menu.oeNoPlans': val.split('\n'),
                })
              }
            />
          </Box>

          {client.contacts &&
            Object.keys(client.contacts).map(i => {
              const c = client.contacts[i];

              return (
                <div key={i}>
                  <DeleteIcon
                    onClick={() => props.onDeleteContact(clientId, i)}
                  />

                  <EditableTextArea
                    label="Description"
                    defaultValue={
                      c.description && JSON.parse(c.description).join('\n')
                    }
                    onChange={val =>
                      _onUpdateContact(
                        i,
                        'description',
                        JSON.stringify(val.split('\n')),
                      )
                    }
                  />

                  <EditableTextInput
                    label="Call"
                    defaultValue={c.call}
                    onChange={val => _onUpdateContact(i, 'call', val)}
                  />

                  <EditableTextInput
                    label="Fax"
                    defaultValue={c.fax}
                    onChange={val => _onUpdateContact(i, 'fax', val)}
                  />

                  <EditableTextInput
                    label="Email"
                    defaultValue={c.email}
                    onChange={val => _onUpdateContact(i, 'email', val)}
                  />
                </div>
              );
            })}

          <Button onClick={() => props.onNewContact()}>
            Add Additonal Contact
          </Button>

          <UploadImage
            title="Logo Image"
            obj={client}
            objPath="images.logo.url"
            dest={`/clients/${clientId}/images/originals/logo.{epoch}.{ext}`}
            metadata={{
              resize: '150',
              gsOutputPath: `/clients/${clientId}/images/logo.{epoch}.{ext}`,
              firestorePath: `/clients/${clientId}/`,
              firestoreField: 'images.logo.url',
            }}
            onUploaded={origUrl => {
              // update the database record
              _onUpdate({ 'images.logo.origUrl': origUrl });
            }}
          />

          <UploadImage
            title="Enroll Button"
            obj={client}
            objPath="images.enroll.url"
            dest={`/clients/${clientId}/images/originals/enroll.{epoch}.{ext}`}
            metadata={{
              resize: '100',
              gsOutputPath: `/clients/${clientId}/images/enroll.{epoch}.{ext}`,
              firestorePath: `/clients/${clientId}/`,
              firestoreField: 'images.enroll.url',
            }}
            onUploaded={origUrl => {
              // update the database record
              _onUpdate({ 'images.enroll.origUrl': origUrl });
            }}
          />
        </Box>
      </Paper>
    </OrgsProvider>
  );
};

ClientDetails = connect(
  state => ({
    client: state.Clients.current,
  }),
  dispatch => ({
    onGetClientDetails: (...args) => dispatch(getClientDetails(...args)),
    onUpdateDetails: (...args) => dispatch(updateClientDetails(...args)),
    onDeleteClient: (...args) => dispatch(deleteClient(...args)),

    onNewContact: () => dispatch(newContact()),
    onUpdateContact: (...args) => dispatch(updateContact(...args)),
    onDeleteContact: (...args) => dispatch(deleteContact(...args)),
  }),
)(ClientDetails);

export default ClientDetails;
