import { combineReducers } from 'redux';
import { Auth } from './Auth';
import { App } from './App';
import { Clients } from './Clients';
import { Carriers } from './Carriers';
import { Events } from './Events';
import { Organizations } from './Organizations';

export const rootReducer = combineReducers({
  App,
  Auth,
  Clients,
  Events,
  Organizations,
  Carriers,
});
export { rootReducer as default };
