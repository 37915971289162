import { useContext, useState } from 'react';
import { EditableTextInput } from '../shared/TextInput';
import { UploadFile } from '../shared/UploadFile';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { UrlInfo } from './UrlInfo';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FirebaseContext } from '../../contexts/firebase';
import { addContentMenu, updateContent } from '../../actions/Events';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  mapArrayToObject,
  mapObjectArrayToArray,
} from '../../utils/objectArrays';

const conditions = [
  { label: 'None', id: undefined },
  { label: 'RETA_TRUST_PLAN_1', id: 'RETA_TRUST_PLAN_1' },
  { label: 'RETA_TRUST_PLAN_S1', id: 'RETA_TRUST_PLAN_S1' },
  { label: 'RETA_TRUST_PLAN_2', id: 'RETA_TRUST_PLAN_2' },
  { label: 'RETA_TRUST_PLAN_S2', id: 'RETA_TRUST_PLAN_S2' },
  { label: 'RETA_TRUST_PLAN_3', id: 'RETA_TRUST_PLAN_3' },
  { label: 'RETA_TRUST_PLAN_4', id: 'RETA_TRUST_PLAN_4' },
  { label: 'RETA_TRUST_PLAN_5', id: 'RETA_TRUST_PLAN_5' },
  { label: 'HAS_HSA', id: 'HAS_HSA' },
  { label: 'HAS_PPO', id: 'HAS_PPO' },
  { label: 'HAS_EPO', id: 'HAS_EPO' },
  { label: 'HAS_DEPO', id: 'HAS_DEPO' },
  { label: 'HAS_ACO', id: 'HAS_ACO' },
  { label: 'HAS_HPN', id: 'HAS_HPN' },
];

export let Menu = ({
  contentId,
  langId,
  language,
  menuId,
  menu,
  onAddMenu,
  onUpdateContent,
  ...props
}) => {
  const { clientId, eventId, carrierId } = useParams();
  const { firebase, database } = useContext(FirebaseContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `sub-menu-${contentId}-${langId}-${menuId}`,
  });

  const docRef = database.doc(
    `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
  );

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      ref={setNodeRef}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        width: '100%',
        zIndex: isDragging ? 1000 : 'auto',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton
              {...listeners}
              {...attributes}
              aria-label="drag"
              size="small"
            >
              <DragIndicatorIcon color="primary" fontSize="inherit" />
            </IconButton>

            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: menu.title }}
              />
              <Typography variant="caption">({language})</Typography>
            </Box>
          </Box>

          <IconButton
            aria-label="drag"
            size="small"
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon color="secondary" />
          </IconButton>
        </Box>

        <MuiMenu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={() => setAnchorEl(null)}
        >
          <MenuList dense>
            <MenuItem
              onClick={async () => {
                const ref = database.doc(
                  `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
                );

                const doc = await ref.get();
                const data = doc.data();

                const menus = mapObjectArrayToArray(
                  data.content[contentId][langId].menus,
                ).filter((m, i) => i !== menuId);

                await ref.update({
                  [`content.${contentId}.${langId}.menus`]:
                    mapArrayToObject(menus),
                });
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </MuiMenu>
      </AccordionSummary>

      <AccordionDetails>
        <Card>
          <CardContent>
            <Box>
              <FormControl fullWidth variant="filled">
                <Stack spacing={2}>
                  <Autocomplete
                    size="small"
                    id="condition"
                    options={conditions}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Condition"
                      />
                    )}
                    value={menu.condition}
                    onChange={async (event, newValue) => {
                      const doc = await docRef.get();
                      const data = doc.data();
                      data.content[contentId][langId].menus[menuId].condition =
                        newValue.id === 'none'
                          ? firebase.firestore.FieldValue.delete()
                          : newValue.id;
                      docRef.set(data);
                    }}
                  />

                  <EditableTextInput
                    label="Title"
                    defaultValue={menu.title}
                    onChange={async val => {
                      const doc = await docRef.get();
                      const data = doc.data();
                      data.content[contentId][langId].menus[menuId].title = val;
                      docRef.set(data);
                    }}
                  />

                  <EditableTextInput
                    label="Url"
                    defaultValue={menu.url}
                    onChange={async val => {
                      const doc = await docRef.get();
                      const data = doc.data();
                      data.content[contentId][langId].menus[menuId].url = val;
                      docRef.set(data);
                    }}
                  />

                  <UrlInfo url={menu.url} />

                  <UploadFile
                    index={`${contentId}-${langId}-${menuId}`}
                    dest={`/clients/${clientId}/events/${eventId}/carriers/${carrierId}/{basename}.{ext}`}
                    onUploaded={async url => {
                      const doc = await docRef.get();
                      const data = doc.data();
                      data.content[contentId][langId].menus[menuId].url = url;
                      docRef.set(data);
                    }}
                  />
                </Stack>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

Menu = withRouter(
  connect(
    (state, ownProps) => {
      return {
        ...ownProps,
        clientId: ownProps.match.params.clientId,
        eventId: ownProps.match.params.eventId,
        carrierId: ownProps.match.params.carrierId,
      };
    },
    dispatch => ({
      onAddMenu: (...args) => dispatch(addContentMenu(...args)),
      onUpdateContent: (...args) => dispatch(updateContent(...args)),
    }),
  )(Menu),
);
