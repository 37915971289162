import objectWalk from 'object-walk';
import _isPlainObject from 'lodash.isplainobject';

export const isPlainObject = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

const areAllKeysNumbers = obj => {
  return Object.keys(obj)
    .map(v => !isNaN(v))
    .every(v => v);
};

const convertObjectArrayToArray = item => {
  // iterate over all the keys
  return Object.entries(item).reduce((acc, cur) => {
    // if the value is an object, then walk it
    if (isPlainObject(cur[1])) {
      acc[parseInt(cur[0])] = walkObjArraysToArrays(cur[1]);
    } else {
      acc[parseInt(cur[0])] = cur[1];
    }
    return acc;
  }, []);
};

export const walkObjArraysToArrays = item => {
  if (Array.isArray(item)) {
    return item.map(i => walkObjArraysToArrays(i));
  }

  // check if the incoming object is an object array
  if (isPlainObject(item) && areAllKeysNumbers(item)) {
    return convertObjectArrayToArray(item);
  }

  // iterate over all keys of the object
  if (isPlainObject(item)) {
    const r = Object.entries(item)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const result = value.map(v => {
            if (isPlainObject(v)) {
              return walkObjArraysToArrays(v);
            }
            return v;
          });
          return [key, result];
        }

        // if the value is an object and all it's keys are numbers, then it's an object array
        if (isPlainObject(value) && areAllKeysNumbers(value)) {
          return [key, convertObjectArrayToArray(value)];
        }

        // if the value is an object, then walk it
        if (isPlainObject(value)) {
          return [key, walkObjArraysToArrays(value)];
        }

        return [key, value];
      })
      // reduce the key, value pairs into an object
      .reduce((acc, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      }, {});

    return r;
  }

  return item;
};

export const walkArraysToObjectArrays = item => {
  if (Array.isArray(item)) {
    return item
      .filter(d => d !== undefined)
      .reduce((acc, cur, i) => {
        if (Array.isArray(cur) || isPlainObject(cur)) {
          acc[i] = walkArraysToObjectArrays(cur);
        } else {
          acc[i] = cur;
        }
        return acc;
      }, {});
  }

  if (isPlainObject(item)) {
    // iterate over all keys of the object
    const r = Object.entries(item)
      .map(([key, value]) => {
        // if the value is an array, map it to an object array
        if (Array.isArray(value)) {
          const result = value.reduce((acc, v, i) => {
            // if the value is an object, then walk it
            if (isPlainObject(v)) {
              acc[i] = walkArraysToObjectArrays(v);
            } else {
              acc[i] = v;
            }
            return acc;
          }, {});

          return [key, result];
        }

        // if the value is an object, then walk it
        if (isPlainObject(value)) {
          return [key, walkArraysToObjectArrays(value)];
        }

        return [key, value];
      })
      // reduce the key, value pairs into an object
      .reduce((acc, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      }, {});

    return r;
  }

  return item;
};

/**
 * DEPRECATED FUNCTIONS BELOW
 */

/**
 * @deprecated
 */
export const mapArrayToObject = arr => {
  return arr
    .map((a, i) => {
      if (Array.isArray(a)) {
        return { [i]: mapArrayToObject(a) };
      }
      return { [i]: a };
    })
    .reduce((p, c) => {
      return {
        ...p,
        ...c,
      };
    }, {});
};

/**
 * @deprecated
 */
export const mapObjectArrayToArray = obj => {
  const result = Object.keys(obj).map(key => {
    return obj[key];
  });

  return result;
};

/**
 * convert all `Object Arrays` to arrays
 *
 * @deprecated
 */
export const walkObjArraysToObj = objIn => {
  objectWalk(objIn, (val, prop, obj) => {
    if (
      isPlainObject(val) &&
      // make sure each key is a number
      Object.keys(val)
        .map(v => !isNaN(v))
        .every(v => v)
    ) {
      console.log('-->', val);
      obj[prop] = mapObjectArrayToArray(val);
    }
  });
  return objIn;
};
// export const walkObjArraysToObj = objIn => {
//   for (const [key, value] of Object.entries(objIn)) {
//     if (
//       isPlainObject(value) &&
//       Object.keys(value)
//         .map(v => !isNaN(v))
//         .every(v => v)
//     ) {
//       objIn[key] = walkObjArraysToObj(Object.keys(value).map(k => value[k]));
//     }
//   }
//   // // convert all `Object Arrays` to arrays
//   // objectWalk(objIn, (val, prop, obj) => {
//   //   if (
//   //     _isPlainObject(val) &&
//   //     Object.keys(val)
//   //       .map(v => !isNaN(v))
//   //       .reduce((p, c) => {
//   //         if (!p) {
//   //           return p;
//   //         }
//   //         return c;
//   //       }, true)
//   //   ) {
//   //     obj[prop] = mapObjectArrayToArray(val);
//   //   }
//   // });
//   return objIn;
// };

export const normalizeObjIds = obj => {
  const result = Object.keys(obj)
    .reduce((p, c) => {
      p.push(obj[c]);
      return p;
    }, [])
    .reduce((p, c, i) => {
      p[i] = c;
      return p;
    }, {});
  return result;
};

export const walkObjToObjArray = objIn => {
  return normalizeObjIds(
    objectWalk(objIn, (val, prop, obj) => {
      // delete undefined values
      if (val === undefined) {
        delete obj[prop];
      }
      // normalize id's of `object arrays`
      if (isPlainObject(val)) {
        obj[prop] = normalizeObjIds(val);
      }
      // Compact arrays
      if (Array.isArray(val)) {
        obj[prop] = val.reduce((p, c) => {
          if (!c) {
            return null;
          }
          p.push(c);
          return p;
        }, []);
      }
    }),
  );
};
