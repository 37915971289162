/* eslint-disable */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from './components/shared/Spinner';
import { AuthApp } from './AppAuth';
import { UnauthApp } from './AppUnauth';
import { COLORS } from './constants';
// import { MessageList } from 'components/messages/MessageList';

const App = ({ isAuthenticated, initialized }) => (
  <Router>
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        color: COLORS.TEXT,
        overflow: 'auto',
      }}
    >
      {!initialized && <Spinner />}

      {initialized && isAuthenticated && <AuthApp />}

      {initialized && !isAuthenticated && <UnauthApp />}
    </div>
  </Router>
);

export default connect(state => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    initialized: state.Auth.initialized,
  };
})(App);
