import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { EditableTextInput } from '../shared/TextInput';
import { useParams, withRouter } from 'react-router';
import { updateOrg, addEventOrgMedia } from '../../actions/Events';
import { Flexbox } from '../shared/Flexbox';
import { Media } from './Media';
import { DropDownMenu } from '../shared/DropDownMenu';
import { Wrapper } from './style';
import { FirebaseContext } from '../../contexts/firebase';
import {
  walkArraysToObjectArrays,
  walkObjArraysToObj,
  walkObjToObjArray,
} from '../../utils/objectArrays';

export let OtherPlan = ({
  carrier,
  carrierFullName,
  description,
  category,
  index,
  media,
  onUpdate,
  onAddMedia,
  data: plan = {},
  eventOrg,
}) => {
  const { database } = useContext(FirebaseContext);
  const { clientId, eventId, orgId } = useParams();
  const [org, setOrg] = useState({});

  // useEffect(() => {
  //   const unsub = database
  //     .doc(`clients/${clientId}/events/${eventId}/organizations/${orgId}`)
  //     .onSnapshot(doc => {
  //       setOrg(walkObjArraysToObj(doc.data()));
  //     });

  //   return unsub;
  // }, []);

  const handleAddMedia = async index => {
    console.log({ eventOrg });
    // const path = `clients/${clientId}/events/${eventId}/organizations/${orgId}`;
    // const docRef = database.doc(path);
    // const doc = await docRef.get();
    // const data = walkObjArraysToObj(doc.data());
    // const plan = eventOrg.plans?.available?.other?[index] || {};
    const media = plan.media || [];
    media.push({});
    eventOrg.plans.available.other[index].media = media;
    const data = walkArraysToObjectArrays(eventOrg.plans.available.other);
    console.log({ data });

    const docRef = database
      .doc(`clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .update({
        'plans.available.other': data,
      });
  };

  return (
    <Wrapper>
      <EditableTextInput
        label="Carrier"
        defaultValue={carrier}
        onChange={val => onUpdate('carrier', val)}
      />

      <EditableTextInput
        label="Carrier Full Name"
        defaultValue={carrierFullName}
        onChange={val => onUpdate('carrierFullName', val)}
      />

      <EditableTextInput
        label="Description"
        defaultValue={description}
        onChange={val => onUpdate('description', val)}
      />

      <DropDownMenu
        style={{ width: 250 }}
        label="Category"
        options={[
          { name: 'None', value: null },
          { name: 'MEDICAL', value: 'MEDICAL' },
          { name: 'DENTAL', value: 'DENTAL' },
          { name: 'VISION', value: 'VISION' },
          { name: 'RX', value: 'RX' },
          { name: 'LTD', value: 'LTD' },
          { name: 'STD', value: 'STD' },
          { name: 'AD&D', value: 'AD&D' },
          { name: 'LIFE', value: 'LIFE' },
          { name: 'EAP', value: 'EAP' },
          { name: 'WELLNESS', value: 'WELLNESS' },
        ]}
        value={category}
        onChange={e => onUpdate('category', e.target.value)}
      />

      <Flexbox>
        <h2>Media</h2>
        <button onClick={() => handleAddMedia(index)}>+</button>
      </Flexbox>

      <Flexbox
        style={{
          overflow: 'auto',
        }}
      >
        {media &&
          media.map((m, i) => {
            return <Media key={i} index={i} parentIndex={index} data={m} />;
          })}
      </Flexbox>
    </Wrapper>
  );
};

OtherPlan = withRouter(
  connect(
    (state, ownProps) => {
      return {
        ...ownProps,
        carrier: ownProps.data.carrier,
        carrierFullName: ownProps.data.carrierFullName,
        description: ownProps.data.description,
        category: ownProps.data.category,
        media: ownProps.data.media,
        clientId: ownProps.match.params.clientId,
        eventId: ownProps.match.params.eventId,
        orgId: ownProps.match.params.orgId,
        index: ownProps.index,
      };
    },
    dispatch => {
      return {
        onUpdate: (...args) => dispatch(updateOrg(...args)),
        onAddMedia: (...args) => dispatch(addEventOrgMedia(...args)),
      };
    },
    (stateProps, dispatchProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        onUpdate: (key, val) =>
          dispatchProps.onUpdate(
            stateProps.clientId,
            stateProps.eventId,
            stateProps.orgId,
            { [`plans.available.other.${stateProps.index}.${key}`]: val },
          ),
      };
    },
    {
      areOwnPropsEqual: () => false,
    },
  )(OtherPlan),
);
