import React from 'react';
import { FlexColumn } from '../shared/Flexbox';
import { ms, COLORS } from '../../constants';

export const Label = props => (
  <div
    style={{
      color: COLORS.CYAN,
      fontSize: ms(2),
      width: '100%',
      marginTop: ms(1),
    }}
  >
    {props.children}
  </div>
);

console.log('ms', ms);

export const DropDownMenu = props => {
  return (
    <FlexColumn
      style={{
        paddingTop: ms(10),
        paddingBottom: ms(10),
        fontSize: ms(2),
        alignItems: 'baseline',
        ...props.style,
      }}
    >
      <select
        value={props.value}
        placeholder="Farts"
        style={{
          backgroundColor: COLORS.DARK,
          color: COLORS.CYAN,
          width: '100%',
          height: 25,
        }}
        id={props.id}
        onChange={props.onChange}
      >
        {props.options.map((option, i) => {
          return (
            <option
              key={i}
              value={option.value}
              disabled={option.disabled || false}
            >
              {option.name}
            </option>
          );
        })}
      </select>
      <Label>{props.label}</Label>
    </FlexColumn>
  );
};
