import { SET_API_HOST } from '../actions/App';

const initState = {
  apiHost: null,
};

export const App = (state = initState, action) => {
  switch (action.type) {
    case SET_API_HOST:
      return {
        ...state,
        apiHost: action.apiHost,
      };

    default:
      return state;
  }
};
