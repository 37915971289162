import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { updateContent } from '../../actions/Events';
import { EditableTextInput } from '../shared/TextInput';
import { useParams, withRouter } from 'react-router';
import { FirebaseContext } from '../../contexts/firebase';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Stack from '@mui/material/Stack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  mapArrayToObject,
  mapObjectArrayToArray,
} from '../../utils/objectArrays';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const conditions = [
  { id: 'none', label: 'None' },
  { id: 'HAS_HSA', label: 'HAS_HSA' },
  { id: 'HAS_EPO', label: 'HAS_EPO' },
  { id: 'HAS_DEPO', label: 'HAS_DEPO' },
  { id: 'HAS_PPO', label: 'HAS_PPO' },
  { id: 'HAS_ACO', label: 'HAS_ACO' },
  { id: 'HAS_HPN', label: 'HAS_HPN' },
];

let Item = ({ item, contentId, sectionId, itemId, onUpdateContent }) => {
  const { clientId, eventId, carrierId } = useParams();
  const { firebase, database } = useContext(FirebaseContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `item-${contentId}-${sectionId}-${itemId}`,
  });

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      ref={setNodeRef}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        width: '100%',
        zIndex: isDragging ? 1000 : 'auto',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              {...listeners}
              {...attributes}
              aria-label="drag"
              size="small"
            >
              <DragIndicatorIcon color="primary" fontSize="inherit" />
            </IconButton>

            <Box sx={{ display: 'flex' }}>
              {console.log(item.title)}
              <Typography variant="body2">{item.title}</Typography>
            </Box>
          </Box>

          <IconButton
            aria-label="drag"
            size="small"
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon color="secondary" />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={() => setAnchorEl(null)}
        >
          <MenuList dense>
            <MenuItem
              onClick={async () => {
                const ref = database.doc(
                  `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
                );
                const doc = await ref.get();
                const data = doc.data();
                const items = mapObjectArrayToArray(
                  data.videoContent[contentId].sections[sectionId].items,
                ).filter((m, i) => i !== itemId);
                await ref.update({
                  [`videoContent.${contentId}.sections.${sectionId}.items`]:
                    mapArrayToObject(items),
                });

                onUpdateContent(clientId, eventId, carrierId, {
                  [`videoContent.${contentId}.sections.${sectionId}.items`]:
                    items,
                });
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </AccordionSummary>

      <Stack spacing={2}>
        <Autocomplete
          size="small"
          id="condition"
          autoHighlight
          disablePortal
          options={conditions}
          renderInput={params => (
            <TextField {...params} variant="filled" label="Condition" />
          )}
          value={
            conditions.filter(c => c.id === conditions.id)[0] || conditions[0]
          }
          onChange={(event, newValue) => {
            onUpdateContent(clientId, eventId, carrierId, {
              [`videoContent.${contentId}.sections.${sectionId}.items.${itemId}.condition`]:
                newValue.id === 'none'
                  ? firebase.firestore.FieldValue.delete()
                  : newValue.id,
            });
          }}
        />

        <EditableTextInput
          label="Title"
          defaultValue={item.title}
          onChange={val =>
            onUpdateContent(clientId, eventId, carrierId, {
              [`videoContent.${contentId}.sections.${sectionId}.items.${itemId}.title`]:
                val,
            })
          }
        />

        <EditableTextInput
          label="Url"
          defaultValue={item.url}
          onChange={val =>
            onUpdateContent(clientId, eventId, carrierId, {
              [`videoContent.${contentId}.sections.${sectionId}.items.${itemId}.url`]:
                val,
            })
          }
        />
      </Stack>
    </Accordion>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        clientId: ownProps.match.params.clientId,
        eventId: ownProps.match.params.eventId,
        carrierId: ownProps.match.params.carrierId,
        ...ownProps,
      };
    },
    dispatch => ({
      onUpdateContent: (...args) => dispatch(updateContent(...args)),
    }),
    (stateProps, dispatchProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        onUpdateContent: obj =>
          dispatchProps.onUpdateContent(
            stateProps.clientId,
            stateProps.eventId,
            stateProps.carrierId,
            obj,
          ),
      };
    },
    { areOwnPropsEqual: () => false },
  )(Item),
);
