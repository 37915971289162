import React, { useContext, useEffect, useReducer } from 'react';
import { FirebaseContext } from './firebase';

export const CarriersContext = React.createContext([]);

export const CarriersProvider = ({ clientId, children }) => {
  const { database } = useContext(FirebaseContext);

  const [carriers, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'UPDATE':
        return action.carriers;
      default:
        return state;
    }
  }, []);

  /**
   * Get the list of carriers
   */
  useEffect(() => {
    const unsub = database.collection(`/carriers`).onSnapshot(docs => {
      const carriers = docs.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      dispatch({ type: 'UPDATE', carriers });
    });

    return unsub;
  }, [clientId, database]);

  return (
    <CarriersContext.Provider value={carriers}>
      {children}
    </CarriersContext.Provider>
  );
};
