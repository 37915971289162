const config = {
  apiKey:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_API_KEY) ||
    'AIzaSyAZWSHeR4vjUIpdL1DtSdflF6x59UQt72s',
  authDomain:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_AUTH_DOMAIN) ||
    'forty8-ihc-e908.firebaseapp.com',
  databaseURL:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_DB_URL) ||
    'https://forty8-ihc-e908.firebaseio.com',
  projectId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_PROJECT_ID) ||
    'forty8-ihc-e908',
  storageBucket:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_BUCKET) ||
    'forty8-ihc-e908.appspot.com',
  messagingSenderId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_MSG_SENDER_ID) ||
    '767681257386',
  appId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_APP_ID) ||
    '1:767681257386:web:b375aa66c90216ea1755d3',
};

export default config;
