import {
  SET_CARRIER_LIST,
  SET_CARRIER_DETAILS,
  NEW_CARRIER_CONTACT,
  RESET_CARRIER,
} from '../actions/Carriers';

const initState = {
  fetching: true,
  carriers: [],
  current: {
    contacts: {
      0: {},
    },
  },
};

export const Carriers = (state = initState, action) => {
  switch (action.type) {
    case SET_CARRIER_LIST:
      return {
        ...state,
        fetching: false,
        carriers: action.carriers,
      };

    case SET_CARRIER_DETAILS:
      if (!action.carrier.contacts) {
        action.carrier.contacts = {
          0: {},
        };
      }

      return {
        ...state,
        fetching: false,
        current: action.carrier,
      };

    case NEW_CARRIER_CONTACT: {
      const contacts = state.current.contacts;
      contacts[Object.keys(contacts).length] = {};

      return {
        ...state,
        current: {
          ...state.current,
          contacts,
        },
      };
    }

    case RESET_CARRIER:
      return {
        ...state,
        current: initState.current,
      };

    default:
      return state;
  }
};
