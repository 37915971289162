import React from 'react';
import { connect } from 'react-redux';
import {
  getOrgDetails,
  updateOrgDetails,
  deleteOrg,
  newContact,
  updateContact,
  deleteContact,
} from '../../actions/Organizations';
import { getClientDetails } from '../../actions/Clients';
import { ms } from '../../constants';
import { UploadImage } from '../shared/UploadImage';
import { EditableTextInput, EditableTextArea } from '../shared/TextInput';
import { Button } from '../shared/Button';
import { DeleteIcon } from '../shared/DeleteIcon';
import { Flexbox } from '../shared/Flexbox';
import _has from 'lodash.has';

class _OrgDetails extends React.Component {
  contacts = [];

  componentWillMount() {
    this.unsubs = [
      this.props.onGetOrgDetails(
        this.props.match.params.clientId,
        this.props.match.params.orgId,
      ),
      this.props.onGetClientInfo(this.props.match.params.clientId),
    ];
  }

  componentWillUnmount() {
    this.unsubs.forEach(f => f());
  }

  _onUpdate(obj) {
    return this.props.onUpdateDetails(
      this.props.match.params.clientId,
      this.props.match.params.orgId,
      obj,
    );
  }

  _onUpdateContact(id, field, val) {
    return this.props.onUpdateContact(
      this.props.match.params.clientId,
      this.props.match.params.orgId,
      id,
      field,
      val,
    );
  }

  render() {
    const { clientId, orgId } = this.props.match.params;
    const { client, org } = this.props;

    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <h4>Organization</h4>
        <h1>
          {client.displayName}:{org.displayName}
        </h1>
        <DeleteIcon
          onClick={() =>
            this.props.onDeleteOrg(clientId, orgId, this.props.history)
          }
        />

        <div
          style={{
            padding: ms(-1),
            width: '100%',
            minWidth: 350,
            boxSizing: 'border-box',
          }}
        >
          <EditableTextInput
            label="Display Name"
            defaultValue={this.props.org.displayName}
            onChange={val => this._onUpdate({ displayName: val })}
          />

          <EditableTextInput
            label="TPA Id"
            defaultValue={this.props.org.tpaId}
            onChange={val => this._onUpdate({ tpaId: val })}
          />

          <div>Address</div>

          <EditableTextInput
            label="Street"
            defaultValue={this.props.org.address.street}
            onChange={val => this._onUpdate({ 'address.street': val })}
          />

          <EditableTextInput
            label="City"
            defaultValue={this.props.org.address.city}
            onChange={val => this._onUpdate({ 'address.city': val })}
          />

          <EditableTextInput
            label="State"
            defaultValue={this.props.org.address.state}
            onChange={val => this._onUpdate({ 'address.state': val })}
          />

          <EditableTextInput
            label="Zip Code"
            defaultValue={this.props.org.address.zipcode}
            onChange={val => this._onUpdate({ 'address.zipcode': val })}
          />

          <div>Contacts</div>

          <Flexbox
            style={{
              alignItems: 'flex-start',
              overflow: 'auto',
            }}
          >
            {org.contacts &&
              Object.keys(org.contacts).map(i => {
                const c = org.contacts[i];

                return (
                  <div
                    key={i}
                    style={{
                      flex: '0 0 500px',
                      border: '1px solid #2d6565',
                      margin: 12,
                      padding: 12,
                      boxSizing: 'border-box',
                    }}
                  >
                    <DeleteIcon
                      onClick={() =>
                        this.props.onDeleteContact(clientId, orgId, i)
                      }
                    />

                    <EditableTextArea
                      label="Description"
                      defaultValue={
                        c.description && JSON.parse(c.description).join('\n')
                      }
                      onChange={val =>
                        this._onUpdateContact(
                          i,
                          'description',
                          JSON.stringify(val.split('\n')),
                        )
                      }
                    />

                    <EditableTextInput
                      label="Call"
                      defaultValue={c.call}
                      onChange={val => this._onUpdateContact(i, 'call', val)}
                    />

                    <EditableTextInput
                      label="Fax"
                      defaultValue={c.fax}
                      onChange={val => this._onUpdateContact(i, 'fax', val)}
                    />

                    <EditableTextInput
                      label="Email"
                      defaultValue={c.email}
                      onChange={val => this._onUpdateContact(i, 'email', val)}
                    />
                  </div>
                );
              })}
          </Flexbox>

          <Button onClick={() => this.props.onNewContact()}>
            Add Additonal Contact
          </Button>
        </div>

        <div>
          <h4>Images</h4>

          <div
            style={{
              border: '1px solid',
              margin: 12,
              padding: 12,
              boxSizing: 'border-box',
            }}
          >
            <UploadImage
              title="Background Image"
              obj={this.props.org}
              objPath="images.background.url"
              dest={`/clients/${clientId}/orgs/${orgId}/images/originals/background.{epoch}.{ext}`}
              metadata={{
                resize: '800',
                gsOutputPath: `/clients/${clientId}/orgs/${orgId}/images/background.{epoch}.{ext}`,
                firestorePath: `/clients/${clientId}/organizations/${orgId}`,
                firestoreField: 'images.background.url',
              }}
              onUploaded={origUrl => {
                // update the database record
                this._onUpdate({ 'images.background.origUrl': origUrl });
              }}
            />

            <EditableTextInput
              label="Description"
              defaultValue={
                _has(this.props.org, 'images.background.description') &&
                this.props.org.images.background.description
              }
              onChange={val =>
                this._onUpdate({
                  'images.background.description': val,
                })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export const OrgDetails = connect(
  state => ({
    client: state.Clients.current,
    org: state.Organizations.org,
  }),
  dispatch => ({
    onGetOrgDetails: (...args) => dispatch(getOrgDetails(...args)),
    onUpdateDetails: (...args) => dispatch(updateOrgDetails(...args)),
    onGetClientInfo: (...args) => dispatch(getClientDetails(...args)),
    onDeleteOrg: (...args) => dispatch(deleteOrg(...args)),
    onNewContact: () => dispatch(newContact()),
    onUpdateContact: (...args) => dispatch(updateContact(...args)),
    onDeleteContact: (...args) => dispatch(deleteContact(...args)),
  }),
)(_OrgDetails);
