export const NEW_ERROR_MESSAGE = 'NEW_ERROR_MESSAGE';
export const newErrorMsg = (message, details) => {
  return dispatch => {
    dispatch({
      type: NEW_ERROR_MESSAGE,
      message,
      details,
    });
  };
};

export const NEW_SUCCESS_MESSAGE = 'NEW_SUCCESS_MESSAGE';
export const newSuccessMsg = (message, details) => {
  return dispatch => {
    dispatch({
      type: NEW_SUCCESS_MESSAGE,
      message,
      details,
    });
  };
};

export const NEW_WARN_MESSAGE = 'NEW_WARN_MESSAGE';
export const newWarnMsg = (message, details) => {
  return dispatch => {
    dispatch({
      type: NEW_WARN_MESSAGE,
      message,
      details,
    });
  };
};

export const NEW_INFO_MESSAGE = 'NEW_INFO_MESSAGE';
export const newInfoMsg = (message, details) => {
  return dispatch => {
    dispatch({
      type: NEW_INFO_MESSAGE,
      message,
      details,
    });
  };
};
