import React from 'react';
// import { scale as s } from '../../constants';
import { Title } from '../shared/Title';
import { Type } from '../shared/Type';

export const PageTitle = props => (
  <div
    style={{
      // marginBottom: s[4],
      ...props.style,
    }}
  >
    <Title
      style={{
        // paddingBottom: s[0],
        ...props.titleStyle,
      }}
    >
      {props.title}
    </Title>
    <Type>{props.type}</Type>
  </div>
);
