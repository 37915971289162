import React, { useState } from 'react';

export const DeleteIcon = ({ onClick = () => {} }) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <div>
      {!confirm && (
        <div onClick={() => setConfirm(true)}>
          <i className="fas fa-trash"></i>
        </div>
      )}

      {confirm && (
        <div
          onClick={() => {
            onClick();
            setConfirm(false);
          }}
        >
          <i className="fas fa-check"></i>
        </div>
      )}
    </div>
  );
};

export default DeleteIcon;
