import React, { useState, useEffect } from 'react';

export const ConfigContext = React.createContext({
  apiHost: '',
});

export const ConfigProvider = ({ children }) => {
  const [value, setValue] = useState({
    apiHost: '',
    setApiHost: val => setValue(prev => ({ ...prev, apiHost: val })),
  });

  useEffect(() => {
    const fetchApiHost = async () => {
      const res = await fetch(window.location.origin + '/apiHost');
      const _apiHost = await res.text();
      value.setApiHost(_apiHost);
      console.debug('apiHost:', _apiHost);
    };

    fetchApiHost();
    // eslint-disable-next-line
  }, []);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
