import React from 'react';
import { ms as s, COLORS } from '../../constants';
import Color from 'color2';

export class Checkbox extends React.Component {
  toggleCheckboxChange = e => {
    this.props.handleInputChange(e.target.checked);
  };

  render() {
    return (
      <div
        style={{
          marginBottom: s[4],
        }}
      >
        <div
          style={{
            color: COLORS.PINK,
            marginBottom: s[2],
            fontSize: s[2],
          }}
        >
          {this.props.label}
        </div>
        <div
          style={{
            fontSize: s[3],
            color: new Color(COLORS.PINK).lighten(0.2),
          }}
        >
          <input
            type="checkbox"
            defaultChecked={this.props.defaultChecked}
            onClick={this.toggleCheckboxChange}
          />
        </div>
      </div>
    );
  }
}

Checkbox.defaultProps = {
  handleInputChange: () => {},
  defaultChecked: false,
};
