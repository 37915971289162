import { newErrorMsg } from './Messages';

export const SET_CARRIER_LIST = 'SET_CARRIER_LIST';
export const getCarrierList = () => {
  return dispatch => {
    return global.fb.database.collection('carriers').onSnapshot(
      docs => {
        dispatch({
          type: SET_CARRIER_LIST,
          carriers: docs.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
        });
      },
      err => {
        newErrorMsg('Unable to get the carrier list', err.stack);
        console.error(err);
      },
    );
  };
};

export const SET_CARRIER_DETAILS = 'SET_CARRIER_DETAILS';
export const getCarrierDetails = carrierId => {
  return dispatch => {
    return global.fb.database
      .collection('carriers')
      .doc(carrierId)
      .onSnapshot(
        doc => {
          dispatch({
            type: SET_CARRIER_DETAILS,
            carrier: doc.data(),
          });
        },
        err => {
          newErrorMsg('Unable to get carrier info', err.stack);
          console.error(err);
        },
      );
  };
};

export const UPDATE_CARRIER_DETAILS = 'UPDATE_CARRIER_DETAILS';
export const updateCarrierDetails = (carrierId, obj) => {
  return async dispatch => {
    try {
      await global.fb.database
        .collection('carriers')
        .doc(carrierId)
        .update(obj);

      dispatch({ type: UPDATE_CARRIER_DETAILS });
    } catch (e) {
      console.error(e);
    }
  };
};

export const NEW_CARRIER = 'NEW_CARRIER';
export const newCarrier = (carrierId, displayName) => {
  return async dispatch => {
    try {
      await global.fb.database.collection('carriers').doc(carrierId).set({
        displayName,
      });

      dispatch({ type: NEW_CARRIER });
    } catch (e) {
      console.error(e);
    }
  };
};

export const RESET_CARRIER = 'RESET_CARRIER';
export const deleteCarrier = (carrierId, history) => {
  return async dispatch => {
    try {
      history.push('/carriers');
      await global.fb.database.collection('carriers').doc(carrierId).delete();
      dispatch({ type: RESET_CARRIER });
    } catch (e) {
      console.error(e);
    }
  };
};

export const NEW_CARRIER_CONTACT = 'NEW_CARRIER_CONTACT';
export const newContact = () => {
  return { type: NEW_CARRIER_CONTACT };
};

export const UPDATE_CARRIER_CONTACT = 'UPDATE_CARRIER_CONTACT';
export const updateContact = (carrierId, index, field, val) => {
  return async dispatch => {
    try {
      await global.fb.database.doc(`/carriers/${carrierId}`).update({
        [`contacts.${index}.${field}`]: val,
      });

      dispatch({ type: UPDATE_CARRIER_CONTACT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const DELETE_CARRIER_CONTACT = 'DELETE_CARRIER_CONTACT';
export const deleteContact = (carrierId, index) => {
  return async dispatch => {
    try {
      await global.fb.database.doc(`/carriers/${carrierId}`).update({
        [`contacts.${index}`]: global.fb.firebase.firestore.FieldValue.delete(),
      });

      dispatch({ type: DELETE_CARRIER_CONTACT, index });
    } catch (e) {
      console.error(e);
    }
  };
};
