import React from 'react';
import { ms, COLORS } from '../../constants';
import Color from 'color2';

export class Button extends React.Component {
  state = {
    hover: false,
  };

  render() {
    return (
      <button
        style={Object.assign(
          {
            backgroundColor: this.props.bgColor,
            color: this.props.color,
            border: `1px solid ${this.props.bgColor}`,
            borderRadius: 0,
            padding: `${ms(2)}px ${ms(2)}px`,
            textTransform: 'uppercase',
            fontSize: ms(2),
            fontFamily: 'Barlow Semi Condensed',
            fontWeight: 600,
            outline: 'none',
          },
          this.state.hover && {
            backgroundColor: this.props.hoverBgColor,
          },
          this.state.click && {
            backgroundColor: this.props.clickBgColor,
            color: this.props.clickColor,
          },
          this.props.disabled && {
            backgroundColor: this.props.disabledColor,
            border: `1px solid ${this.props.disabledColor}`,
          },
          this.props.style,
        )}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onMouseDown={() => this.setState({ click: true })}
        onMouseUp={() => this.setState({ click: false })}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}

Button.defaultProps = {
  color: COLORS.BUTTON_TEXT,
  bgColor: COLORS.BUTTON_BACKGROUND,
  hoverBgColor: new Color(COLORS.BUTTON_BACKGROUND).lighten(0.2),
  clickBgColor: new Color(COLORS.BUTTON_BACKGROUND).lighten(0.4),
  clickColor: new Color(COLORS.BUTTON_TEXT).darken(0.2),
  disabledColor: new Color(COLORS.BUTTON_BACKGROUND).desaturate(0.7),
  onClick: () => console.log('click'),
};
