import {
  SET_ORG_LIST,
  SET_ORG_DETAILS,
  NEW_ORG_CONTACT,
} from '../actions/Organizations';

const initState = {
  fetching: true,
  orgs: [],
  org: {
    address: {},
    contacts: {
      0: {},
    },
    images: {},
  },
};

export const Organizations = (state = initState, action) => {
  switch (action.type) {
    case SET_ORG_LIST:
      return {
        ...state,
        fetching: false,
        orgs: action.orgs,
      };

    case SET_ORG_DETAILS:
      if (!action.org.contacts) {
        action.org.contacts = {};
      }

      if (!action.org.address) {
        action.org.address = {};
      }

      if (!action.org.images) {
        action.org.images = {};
      }

      return {
        ...state,
        fetching: false,
        org: action.org,
      };

    case NEW_ORG_CONTACT: {
      const contacts = state.org.contacts;
      contacts[Object.keys(contacts).length] = {};

      return {
        ...state,
        org: {
          ...state.org,
          contacts,
        },
      };
    }

    default:
      return state;
  }
};
