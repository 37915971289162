import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import {
  addContentMenu,
  addContentLanguage,
  updateContent,
  // orderUp,
  // orderDown,
} from '../../actions/Events';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Divider, MenuList, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentItem from './ContentItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { mapArrayToObject } from '../../utils/objectArrays';
import { FirebaseContext } from '../../contexts/firebase';

const _Content = ({ contentId, content, onUpdateContent, onTranslate }) => {
  const { clientId, carrierId, eventId } = useParams();
  const { firebase } = useContext(FirebaseContext);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: 'content-' + contentId,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  return (
    <Box
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition,
        p: 1,
        zIndex: isDragging ? 1000 : 0,
      }}
    >
      <Menu
        anchorEl={anchorEl}
        open={showMenu}
        onClose={() => setAnchorEl(null)}
      >
        <MenuList dense>
          <MenuItem
            onClick={() => {
              onUpdateContent(clientId, eventId, carrierId, {
                [`content.${contentId}`]: mapArrayToObject([...content, {}]),
              });
            }}
          >
            Add Language
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              onUpdateContent(clientId, eventId, carrierId, {
                [`content.${contentId}`]:
                  firebase.firestore.FieldValue.delete(),
              });
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Language */}
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            direction="row"
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                {...listeners}
                {...attributes}
                aria-label="drag"
                size="small"
              >
                <DragIndicatorIcon color="primary" fontSize="inherit" />
              </IconButton>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
              >
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: content.filter(d => /english/i.test(d.language))[0]
                      ?.title,
                  }}
                ></Typography>
                {content[0].condition && (
                  <Typography variant="overline" sx={{ paddingLeft: 1 }}>
                    ( {content[0].condition} )
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <IconButton
                aria-label="drag"
                size="small"
                onClick={event => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} direction="row">
            {/* Language Items */}
            {content?.map((lang, l) => {
              return (
                <ContentItem
                  key={l}
                  index={l}
                  contentId={contentId}
                  language={lang}
                  englishContent={
                    content.filter(d => /english/i.test(d.language))[0]
                  }
                />
              );
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const Content = connect(
  (state, ownProps) => {
    return {
      // ...ownProps,
      // clientId: ownProps.params.clientId,
      // eventId: ownProps.params.eventId,
      // carrierId: ownProps.params.carrierId,
    };
  },
  dispatch => ({
    onAddMenu: (...args) => dispatch(addContentMenu(...args)),
    onAddLanguage: (...args) => dispatch(addContentLanguage(...args)),
    onUpdateContent: (...args) => dispatch(updateContent(...args)),
  }),
)(_Content);
