import { newErrorMsg } from './Messages';

export const AUTHENTICATED = 'AUTHENTICATED';
export const authenticated = user => {
  return {
    type: AUTHENTICATED,
    uid: user.uid,
    user,
  };
};

export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const unAuthenticated = () => {
  return {
    type: UNAUTHENTICATED,
  };
};

export const getProfile = uid => {
  return dispatch => {
    return global.fb.database
      .collection('users')
      .doc(uid)
      .onSnapshot(
        doc => {
          dispatch(updateProfile(doc.data()));
        },
        err => {
          console.error(err);
          dispatch(newErrorMsg(err.message));
        },
      );
  };
};

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const updateProfile = profile => {
  return {
    type: UPDATE_PROFILE,
    profile,
  };
};
