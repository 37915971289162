export const SET_ORG_LIST = 'SET_ORG_LIST';
export const getOrgs = clientId => {
  return dispatch => {
    return global.fb.database
      .collection('clients')
      .doc(clientId)
      .collection('organizations')
      .onSnapshot(
        docs => {
          dispatch({
            type: SET_ORG_LIST,
            orgs: docs.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }),
          });
        },
        err => {
          console.error(err);
        },
      );
  };
};

export const SET_ORG_DETAILS = 'SET_ORG_DETAILS';
export const getOrgDetails = (clientId, orgId) => {
  return dispatch => {
    return global.fb.database
      .collection('clients')
      .doc(clientId)
      .collection('organizations')
      .doc(orgId)
      .onSnapshot(
        doc => {
          dispatch({
            type: SET_ORG_DETAILS,
            org: {
              ...doc.data(),
              id: doc.id,
            },
          });
        },
        err => {
          console.error(err);
        },
      );
  };
};

export const UPDATE_ORG_DETAILS = 'UPDATE_ORG_DETAILS';
export const updateOrgDetails = (clientId, orgId, obj) => {
  return async dispatch => {
    try {
      await global.fb.database
        .collection('clients')
        .doc(clientId)
        .collection('organizations')
        .doc(orgId)
        .update(obj);

      dispatch({ type: UPDATE_ORG_DETAILS });
    } catch (e) {
      console.error(e);
    }
  };
};

export const NEW_ORG = 'NEW_ORG';
export const newOrg = (clientId, orgId, displayName) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/organizations/${orgId}`)
        .set({
          displayName,
        });

      dispatch({ type: NEW_ORG });
    } catch (e) {
      console.error(e);
    }
  };
};

export const NEW_ORG_CONTACT = 'NEW_ORG_CONTACT';
export const newContact = () => {
  return { type: NEW_ORG_CONTACT };
};

export const UPDATE_ORG_CONTACT = 'UPDATE_ORG_CONTACT';
export const updateContact = (clientId, orgId, index, field, val) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/organizations/${orgId}`)
        .update({
          [`contacts.${index}.${field}`]: val,
        });

      dispatch({ type: UPDATE_ORG_CONTACT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const DELETE_ORG_CONTACT = 'DELETE_ORG_CONTACT';
export const deleteContact = (clientId, orgId, index) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/organizations/${orgId}`)
        .update({
          [`contacts.${index}`]: global.fb.firebase.firestore.FieldValue.delete(),
        });

      dispatch({ type: DELETE_ORG_CONTACT });
    } catch (e) {
      console.error(e);
    }
  };
};

export const DELETE_ORG = 'DELETE_ORG';
export const deleteOrg = (clientId, orgId, history) => {
  return async dispatch => {
    try {
      await global.fb.database
        .doc(`/clients/${clientId}/organizations/${orgId}`)
        .delete();
    } catch (e) {
      console.error(e);
    }

    try {
      await global.fb.storage
        .child(`/clients/${clientId}/orgs/${orgId}`)
        .delete();
    } catch (e) {
      console.error(e);
    }

    history.replace(`/clients/${clientId}`);
    dispatch({ type: DELETE_ORG });
  };
};
