import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { EditableTextArea } from '../shared/TextInput';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Divider } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FirebaseContext } from '../../contexts/firebase';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateContent, addCarrierSideCopyLang } from '../../actions/Events';
import {
  mapArrayToObject,
  mapObjectArrayToArray,
} from '../../utils/objectArrays';

const langOptions = [
  { label: 'English', id: 'english' },
  { label: 'Spanish', id: 'spanish' },
];

export const SideCopyCard = connect(null, dispatch => ({
  onUpdateContent: (...args) => dispatch(updateContent(...args)),
  onAddCarrierSideCopyLang: (...args) =>
    dispatch(addCarrierSideCopyLang(...args)),
}))(({ idx, sideCopy, onUpdateContent, onAddCarrierSideCopyLang }) => {
  const { firebase, database } = useContext(FirebaseContext);
  const { clientId, eventId, carrierId } = useParams();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    // isDragging,
  } = useSortable({
    id: 'side-copy-' + idx,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  return (
    <Box
      ref={setNodeRef}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        // width: '100%',
        // display: 'flex',
        // flexWrap: 'nowrap',
        // overflowX: 'scroll',
        width: 400,
        // opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Card
        sx={{
          mr: 3,
          flex: 0,
          opacity: 0.5,
          width: 'fit-content',
        }}
        variant="outlined"
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <IconButton
              aria-label="drag"
              size="small"
              color="primary"
              {...listeners}
              {...attributes}
            >
              <DragIndicatorIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              aria-label="drag"
              size="small"
              color="secondary"
              onClick={event => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={showMenu}
              onClose={() => setAnchorEl(null)}
            >
              <MenuList dense>
                <MenuItem
                  onClick={() => {
                    console.log(idx, mapArrayToObject([...sideCopy, {}]));
                    onUpdateContent(clientId, eventId, carrierId, {
                      [`sideCopy.${idx}`]: mapArrayToObject([...sideCopy, {}]),
                    });
                  }}
                >
                  Add Language
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={async () => {
                    const ref = database.doc(
                      `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
                    );
                    const doc = await ref.get();
                    const data = doc.data();
                    const sideCopy = mapObjectArrayToArray(data.sideCopy || []);
                    sideCopy.splice(idx, 1);
                    await ref.update({
                      sideCopy: mapArrayToObject(sideCopy),
                    });
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>

          <Stack direction="row" spacing={2}>
            {console.log('side copy', sideCopy)}
            {sideCopy?.map((translation, id) => {
              return (
                <Stack key={id} spacing={2}>
                  <FormControl fullWidth variant="filled">
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="side-copy-language"
                      options={langOptions}
                      sx={{ width: 300 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Language"
                        />
                      )}
                      value={langOptions.find(
                        opt => opt.id === translation.language || '',
                      )}
                      onChange={(event, newValue) => {
                        onUpdateContent(clientId, eventId, carrierId, {
                          [`sideCopy.${idx}.${id}.language`]: newValue?.id,
                        });
                      }}
                    />
                  </FormControl>

                  <EditableTextArea
                    label="Value"
                    defaultValue={
                      translation.value &&
                      JSON.parse(translation.value).join('\n')
                    }
                    enablePreview
                    onChange={val =>
                      onUpdateContent(clientId, eventId, carrierId, {
                        [`sideCopy.${idx}.${id}.value`]: JSON.stringify(
                          val.split('\n'),
                        ),
                      })
                    }
                  />

                  {/* Condition */}
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="condition">Condition</InputLabel>
                    <Select
                      labelId="condition"
                      id="condition"
                      value={translation.condition || 'none'}
                      label="condition"
                      onChange={e =>
                        onUpdateContent(clientId, eventId, carrierId, {
                          [`sideCopy.${idx}.${id}.condition`]:
                            e.target.value === 'none'
                              ? global.fb.firebase.firestore.FieldValue.delete()
                              : e.target.value,
                        })
                      }
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="REQUIRES_MEDICAL">
                        REQUIRES_MEDICAL
                      </MenuItem>
                      <MenuItem value="BUNDLED_WITH_MEDICAL">
                        BUNDLED_WITH_MEDICAL
                      </MenuItem>
                      <MenuItem value="HAS_HSA_AND_KAISER">
                        HAS_HSA_AND_KAISER
                      </MenuItem>
                      <MenuItem value="HAS_HSA">HAS_HSA</MenuItem>
                      <MenuItem value="HAS_KAISER">HAS_KAISER</MenuItem>
                      <MenuItem value="NO_KAISER">NO_KAISER</MenuItem>
                      <MenuItem value="HAS_EPO">HAS_EPO</MenuItem>
                      <MenuItem value="BUNDLED_WITH_MEDICAL_AND_HAS_KAISER">
                        BUNDLED_WITH_MEDICAL_AND_HAS_KAISER
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        onUpdateContent(clientId, eventId, carrierId, {
                          [`sideCopy.${idx}.${id}`]:
                            firebase.firestore.FieldValue.delete(),
                        });
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
});

export default SideCopyCard;
