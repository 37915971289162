import {
  SET_CLIENT_LIST,
  SET_CLIENT_DETAILS,
  NEW_CLIENT_CONTACT,
  RESET_CLIENT,
} from '../actions/Clients';

const initState = {
  fetching: true,
  clients: [],
  current: {
    contacts: {
      0: {},
    },
  },
};

export const Clients = (state = initState, action) => {
  switch (action.type) {
    case SET_CLIENT_LIST:
      return {
        ...state,
        fetching: false,
        clients: action.clients,
      };

    case SET_CLIENT_DETAILS:
      if (!action.client.contacts) {
        action.client.contacts = {
          0: {},
        };
      }

      return {
        ...state,
        fetching: false,
        current: action.client,
      };

    case NEW_CLIENT_CONTACT: {
      const contacts = state.current.contacts;
      contacts[Object.keys(contacts).length] = {};

      return {
        ...state,
        current: {
          ...state.current,
          contacts,
        },
      };
    }

    case RESET_CLIENT:
      return {
        ...state,
        current: initState.current,
      };

    default:
      return state;
  }
};
