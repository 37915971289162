import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UPDATE_PROFILE,
} from '../actions/Auth';

const initState = {
  initialized: false,
  isAuthenticated: false,
  profile: {},
  user: null,
};

export const Auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        initialized: true,
        isAuthenticated: true,
        uid: action.uid,
        fbaseUser: action.user,
      };

    case UNAUTHENTICATED:
      return {
        ...state,
        initialized: true,
        isAuthenticated: false,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };

    default:
      return state;
  }
};
