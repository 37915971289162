import { useContext } from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { FirebaseContext } from '../../contexts/firebase.jsx';
import { useParams } from 'react-router-dom';

const NewOrgModal = ({ open = false, onClose = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { database } = useContext(FirebaseContext);
  const { clientId } = useParams();

  const { control, setValue, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      orgId: '',
      displayName: '',
    },
  });

  const handleOk = async () => {
    const { orgId, displayName } = getValues();
    try {
      await database
        .collection(`/clients/${clientId}/organizations`)
        .doc(orgId)
        .set({
          orgId,
          displayName,
        });
      enqueueSnackbar('Org added', { variant: 'success' });
      reset();
    } catch (e) {
      enqueueSnackbar('Error creating org', { variant: 'error' });
    }
    onClose();
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          '& > *': {
            pb: 2,

            '&:last-child': {
              pb: 0,
            },
          },
        }}
      >
        <Typography variant="h6" component="div">
          New Org
        </Typography>

        <Controller
          name="displayName"
          control={control}
          rules={{ required: true }}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => {
            return (
              <TextField
                {...field}
                onChange={e => {
                  // set the default org id if it's not all ready set
                  setValue(
                    'orgId',
                    e.target.value
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .slice(0, 6),
                  );
                  field.onChange(e);
                }}
                error={error || invalid}
                required
                sx={{ width: '100%' }}
                id="filled-required"
                label="Display Name"
                variant="filled"
                helperText="Human readable name for the org"
              />
            );
          }}
        />

        <Controller
          name="orgId"
          control={control}
          rules={{
            pattern: {
              value: /^[a-z0-9_-]+$/,
              message:
                'Value needs to be all lower case, numbers, underscore or dash',
            },
          }}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => {
            return (
              <TextField
                {...field}
                error={invalid}
                required
                sx={{ width: '100%' }}
                id="filled-required"
                label="Org ID"
                variant="filled"
                helperText={error?.message || 'Used as the internal id'}
              />
            );
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleOk}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewOrgModal;
