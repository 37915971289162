import React from 'react';
import { connect } from 'react-redux';
import { TextInput } from '../shared/TextInput';
import { Button } from '../shared/Button';
// import { FlexColumn } from '../shared/Flexbox';
import { newOrg } from '../../actions/Organizations';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  margin: 12px 0;
  display: flex;
`;

export const Item = styled.div`
  margin: 12px;
  box-sizing: border-box;
`;

const PlusIcon = () => <i className="fas fa-plus" />;

class _NewOrg extends React.Component {
  state = {
    id: '',
    displayName: '',
  };

  render() {
    return (
      <Wrapper>
        <Item>
          <TextInput
            label="Org Id"
            value={this.state.id}
            onChange={e => this.setState({ id: e.target.value })}
          />
        </Item>
        <Item>
          <TextInput
            label="Display Name"
            value={this.state.displayName}
            onChange={e => this.setState({ displayName: e.target.value })}
          />
        </Item>
        <Item>
          <Button
            onClick={async () => {
              await this.props.onNewOrg(
                this.props.match.params.clientId,
                this.state.id,
                this.state.displayName,
              );
              this.props.history.push(
                `/clients/${this.props.clientId}/orgs/${this.state.id}`,
              );
              this.setState({
                id: '',
                displayName: '',
              });
            }}
          >
            <PlusIcon />
          </Button>
        </Item>
      </Wrapper>
    );
  }
}

export const NewOrg = withRouter(
  connect(
    state => {
      return {
        clientId: state.Clients.current.id,
      };
    },
    dispatch => ({
      onNewOrg: (...args) => dispatch(newOrg(...args)),
    }),
  )(_NewOrg),
);
