import React, { useContext, useState, useEffect } from 'react';
import { TextInput } from '../shared/TextInput';
import { Spinner } from '../shared/Spinner';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
// import 'ace-builds/src-noconflict/worker-json';
import 'ace-builds/src-noconflict/theme-nord_dark';
import 'ace-builds/src-noconflict/keybinding-vim';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FirebaseContext } from '../../contexts/firebase';
import useQuery from '../../hooks/useQuery';

export const InspectData = () => {
  const query = useQuery();

  const [payload, setPayload] = useState('');
  const [fetching, setFetching] = useState(false);
  const [id, setId] = useState(query.get('id') || '');
  const { firebase, database } = useContext(FirebaseContext);
  const [original, setOriginal] = useState(null);

  async function fetchData() {
    setFetching(true);

    const doc = await database.doc('presentations/' + id).get();

    setFetching(false);
    const jsonStr = JSON.stringify(doc.data(), null, 2);
    setPayload(jsonStr);
    setOriginal(jsonStr);
  }

  async function savePresentation() {
    const payloadObj = JSON.parse(payload);
    if (payloadObj?.timestamp?.seconds && payloadObj?.timestamp?.nanoseconds) {
      const { seconds, nanoseconds } = payloadObj.timestamp;
      payloadObj.timestamp = new firebase.firestore.Timestamp(
        seconds,
        nanoseconds,
      ).toDate();
    }

    await database.doc('presentations/' + id).set(payloadObj);

    setOriginal(payload);
  }

  function cancelChanges() {
    setPayload(original);
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack spacing={2} sx={{ width: '100%', height: '100%' }}>
        <Stack spacing={2} direction="row">
          <TextField
            sx={{ width: 300 }}
            label="Presentation Id"
            variant="filled"
            defaultValue={id}
            onChange={e => {
              setId(e.target.value);
            }}
          />

          <Button
            variant="outlined"
            onClick={() => {
              fetchData();
            }}
            disabled={id === null || fetching}
          >
            {fetching ? <Spinner /> : 'Fetch Data'}
          </Button>
        </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <AceEditor
            name="payload_editor"
            mode="json"
            width="100%"
            height="100%"
            theme="nord_dark"
            keyboardHandler="vim"
            tabSize={2}
            showPrintMargin={false}
            value={payload}
            editorProps={{ $blockScrolling: true }}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            onChange={value => {
              setPayload(value);
            }}
          />
        </Box>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button color="secondary" onClick={cancelChanges}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={savePresentation}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InspectData;
