import React from 'react';
import { connect } from 'react-redux';
import { EditableTextInput } from '../shared/TextInput';
import { withRouter } from 'react-router';
import { updateOrg } from '../../actions/Events';
import { UploadFile } from '../shared/UploadFile';
import { UrlInfo } from './UrlInfo';
import { Wrapper as _Wrapper } from './style';
import styled from 'styled-components/macro';

const Wrapper = styled(_Wrapper)`
  flex: 0 0 450px;
`;

export let Media = ({
  clientId,
  eventId,
  orgId,
  name,
  type,
  url,
  onUpdate,
}) => {
  return (
    <Wrapper>
      <EditableTextInput
        label="Name"
        defaultValue={name}
        onChange={val => onUpdate('name', val)}
      />

      <div style={{}}>
        <div>Type</div>
        <select
          value={type}
          onChange={e =>
            onUpdate(
              'type',
              e.target.value === 'none'
                ? global.fb.firebase.firestore.FieldValue.delete()
                : e.target.value,
            )
          }
        >
          <option value="none">None</option>
          <option value="URL">URL</option>
        </select>
      </div>

      <EditableTextInput
        label="URL"
        defaultValue={url}
        onChange={val => onUpdate('url', val)}
      />

      <UrlInfo url={url} />

      <UploadFile
        dest={`/clients/${clientId}/events/${eventId}/organizations/${orgId}/media/{basename}.{ext}`}
        onUploaded={url => onUpdate('url', url)}
      />
    </Wrapper>
  );
};

Media = withRouter(
  connect(
    (state, ownProps) => {
      return {
        clientId: ownProps.match.params.clientId,
        eventId: ownProps.match.params.eventId,
        orgId: ownProps.match.params.orgId,
        name: ownProps.data.name,
        url: ownProps.data.url,
        type: ownProps.type,
        index: ownProps.index,
        parentIndex: ownProps.parentIndex,
      };
    },
    dispatch => {
      return {
        onUpdate: (...args) => dispatch(updateOrg(...args)),
      };
    },
    (stateProps, dispatchProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        onUpdate: (key, val) => {
          return dispatchProps.onUpdate(
            stateProps.clientId,
            stateProps.eventId,
            stateProps.orgId,
            {
              [`plans.available.other.${stateProps.parentIndex}.media.${stateProps.index}.${key}`]: val,
            },
          );
        },
      };
    },
  )(Media),
);
