import React from 'react';
import { ms, COLORS } from '../../constants';

export const Type = props => (
  <div
    style={{
      color: COLORS.GREEN,
      fontSize: ms(4),
      lineHeight: 1.3,
      textTransform: 'uppercase',
    }}
  >
    {props.children}
  </div>
);
