import React from 'react';
import { createRoot } from 'react-dom/client';
import { FirebaseProvider } from './contexts/firebase';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ConfigProvider } from './contexts/config';
import GlobalStyle from './GlobalStyle';
import { store } from './store';
import App from './App';
import fbaseProps from './constants/firebase';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './constants/theme';
import { SnackbarProvider } from 'notistack';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />

    <FirebaseProvider {...fbaseProps}>
      <ConfigProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3} dense={true}>
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </ConfigProvider>
    </FirebaseProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
