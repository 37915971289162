import _isPlainObject from 'lodash.isplainobject';
import objectWalk from 'object-walk';
import {
  SET_EVENT_LIST,
  SET_EVENT_DETAILS,
  RESET_EVENT,
  SET_EVENT_CARRIERS,
  SET_EVENT_CARRIER_INFO,
  SET_EVENT_ORGS,
  SET_EVENT_ORG_INFO,
  EVENT_ADD_CARRIER_CONTENT,
  EVENT_ADD_CONTENT_MENU,
  EVENT_ADD_CONTENT_LANGUAGE,
  EVENT_ADD_CARRIER_VIDEO,
  EVENT_ADD_CARRIER_VIDEO_SECTION,
  EVENT_ADD_CARRIER_VIDEO_ITEM,
  EVENT_ADD_ORG_WHATS_NEW,
  EVENT_ADD_ORG_OTHER_PLAN,
  EVENT_ADD_ORG_OTHER_MEDIA,
  EVENT_ADD_CARRIER_SIDE_COPY,
  EVENT_ADD_CARRIER_SIDE_COPY_LANG,
  EVENT_CONTENT_REORDER,
} from '../actions/Events';

const processObj = obj => {
  // convert all `Object Arrays` to arrays
  objectWalk(obj, (val, prop, obj) => {
    if (
      _isPlainObject(val) &&
      Object.keys(val)
        .map(v => !isNaN(v))
        .reduce((p, c) => {
          if (!p) {
            return p;
          }
          return c;
        }, true)
    ) {
      obj[prop] = mapObjectArrayToArray(val);
    }
  });
  return obj;
};

const initState = {
  fetching: true,
  events: [],
  current: {},
  orgs: [],
  org: {
    whatsNew: [],
    plans: {
      available: {
        other: [],
      },
    },
  },
  carriers: [],
  carrier: {
    sideCopy: [[{}]],
    content: [[{}]],
    videoContent: [
      {
        sections: [],
      },
    ],
  },
};

const mapObjectArrayToArray = obj => {
  // calculate the length of the array based on the keys of the object
  const length = Object.keys(obj).reduce((p, c) => {
    if (c > p) {
      return c;
    }
    return null;
  }, 0);

  const result = new Array(length);
  Object.keys(obj).forEach(key => {
    result[key] = obj[key];
  });

  return result;
};

export const Events = (state = initState, action) => {
  switch (action.type) {
    case SET_EVENT_LIST:
      return {
        ...state,
        fetching: false,
        events: action.events,
      };

    case SET_EVENT_DETAILS:
      return {
        ...state,
        fetching: false,
        current: {
          ...action.event,
          eventId: action.eventId,
          clientId: action.clientId,
          eventRef: action.eventRef,
        },
      };

    case RESET_EVENT:
      return {
        ...state,
        current: initState.current,
      };

    case SET_EVENT_ORGS:
      return {
        ...state,
        orgs: action.orgs,
      };

    case SET_EVENT_CARRIERS:
      return {
        ...state,
        carriers: action.carriers,
      };

    case SET_EVENT_CARRIER_INFO: {
      const carrier = {
        ...action.carrier,
      };

      if (!carrier.sideCopy) {
        carrier.sideCopy = [[{}]];
      } else {
        carrier.sideCopy = mapObjectArrayToArray(carrier.sideCopy).map(copy => {
          return mapObjectArrayToArray(copy);
        });
      }

      if (!carrier.content) {
        carrier.content = [[{}]];
      } else {
        carrier.content = mapObjectArrayToArray(carrier.content).map(
          content => {
            return mapObjectArrayToArray(content).map(lang => {
              return {
                ...lang,
                menus: lang.menus && mapObjectArrayToArray(lang.menus),
              };
            });
          },
        );
      }

      if (!carrier.videoContent) {
        carrier.videoContent = [];
      } else {
        carrier.videoContent = mapObjectArrayToArray(carrier.videoContent).map(
          content => {
            return {
              ...content,
              sections: !content.sections
                ? []
                : mapObjectArrayToArray(content.sections).map(s => {
                    return {
                      ...s,
                      items: !s.items ? [] : mapObjectArrayToArray(s.items),
                    };
                  }),
            };
          },
        );
      }

      return {
        ...state,
        carrier,
      };
    }

    case SET_EVENT_ORG_INFO: {
      return {
        ...state,
        org: processObj(action.org),
      };
    }

    case EVENT_ADD_CARRIER_CONTENT:
      return {
        ...state,
        carrier: {
          ...state.carrier,
          content: [...state.carrier.content, [{}]],
        },
      };

    case EVENT_ADD_CONTENT_MENU: {
      const content = state.carrier.content;
      if (!content[action.contentId][action.languageId].menus) {
        content[action.contentId][action.languageId].menus = [];
      }
      content[action.contentId][action.languageId].menus.push({});

      return {
        ...state,
        carrier: {
          ...state.carrier,
          content,
        },
      };
    }

    case EVENT_ADD_CONTENT_LANGUAGE: {
      const content = state.carrier.content;
      content[action.contentId].push({});

      return {
        ...state,
        carrier: {
          ...state.carrier,
          content,
        },
      };
    }

    case EVENT_ADD_CARRIER_VIDEO:
      return {
        ...state,
        carrier: {
          ...state.carrier,
          videoContent: [...state.carrier.videoContent, { sections: [] }],
        },
      };

    case EVENT_ADD_CARRIER_VIDEO_SECTION: {
      const videoContent = state.carrier.videoContent;
      videoContent[action.contentId].sections.push({});

      return {
        ...state,
        carrier: {
          ...state.carrier,
          videoContent,
        },
      };
    }

    case EVENT_ADD_CARRIER_VIDEO_ITEM: {
      const videoContent = state.carrier.videoContent;
      if (!videoContent[action.contentId].sections[action.sectionId].items) {
        videoContent[action.contentId].sections[action.sectionId].items = [];
      }
      videoContent[action.contentId].sections[action.sectionId].items.push({});

      return {
        ...state,
        carrier: {
          ...state.carrier,
          videoContent,
        },
      };
    }

    case EVENT_ADD_ORG_WHATS_NEW: {
      const newState = state;
      if (!newState.org.whatsNew) {
        newState.org.whatsNew = [];
      }
      newState.org.whatsNew.push({ title: '', details: '' });
      return newState;
    }

    case EVENT_ADD_ORG_OTHER_PLAN: {
      const newState = state;
      if (!newState.org.plans) {
        newState.org.plans = {};
      }
      if (!newState.org.plans.available) {
        newState.org.plans.available = {};
      }
      if (!newState.org.plans.available.other) {
        newState.org.plans.available.other = [];
      }

      newState.org.plans.available.other.push({});

      return newState;
    }

    case EVENT_ADD_ORG_OTHER_MEDIA: {
      const newState = state;
      if (!newState.org.plans.available.other[action.index].media) {
        newState.org.plans.available.other[action.index].media = [];
      }
      newState.org.plans.available.other[action.index].media.push({});
      return newState;
    }

    case EVENT_ADD_CARRIER_SIDE_COPY:
      return {
        ...state,
        carrier: {
          ...state.carrier,
          sideCopy: [...state.carrier.sideCopy, [[]]],
        },
      };

    case EVENT_ADD_CARRIER_SIDE_COPY_LANG: {
      const sideCopy = state.carrier.sideCopy;
      sideCopy[action.copyId].push({});

      return {
        ...state,
        carrier: {
          ...state.carrier,
          sideCopy,
        },
      };
    }

    case EVENT_CONTENT_REORDER:
      return {
        ...state,
        carrier: {
          ...state.carrier,
          content: action.list,
        },
      };

    default:
      return state;
  }
};
