import { useState, useContext, useRef } from 'react';
import { Spinner } from '../shared/Spinner';
import { FirebaseContext } from '../../contexts/firebase';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { nanoid } from 'nanoid';

export const UploadFile = ({
  dest,
  index = nanoid(3),
  onClick = () => {},
  onUploaded = () => {},
  metadata = {},
}) => {
  const { storage } = useContext(FirebaseContext);

  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const inputElem = useRef();

  const upload = async () => {
    console.log({ inputElem });
    const [result, basename, ext] = file.name.match(/(.+)(?:\.)([^.]+)$/);
    if (!result) {
      console.warn('Unable to get file parts  for:', file.name);
    }
    const epoch = new Date().getTime();
    const gsPath = dest
      .replace('{basename}', basename)
      .replace('{epoch}', epoch)
      .replace('{ext}', ext);

    // append the timestamp and extension to the output path if given
    if (metadata.gsOutputPath) {
      metadata.gsOutputPath = metadata.gsOutputPath
        .replace('{basename}', basename)
        .replace('{epoch}', epoch)
        .replace('{ext}', ext);
    }

    setUploading(true);

    // upload the file
    await storage.child(gsPath).put(file, {
      customMetadata: metadata,
    });

    setUploading(false);

    // clear the file input
    setFile(null);
    inputElem.current.value = null;

    // get the storage url
    const origUrl = await global.fb.storage.child(gsPath).getDownloadURL();
    onUploaded(origUrl, gsPath);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <input
          id={`upload-file-${index}`}
          ref={inputElem}
          style={{ display: 'none' }}
          type="file"
          onChange={e => {
            console.log(e.target.files[0]);
            setFile(e.target.files[0]);
          }}
          color="primary"
        />
        <label htmlFor={`upload-file-${index}`}>
          <Button size="small" variant="outlined" component="span">
            Choose File
          </Button>
        </label>

        {file && (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={upload}
            endIcon={<UploadIcon />}
          >
            Upload
          </Button>
        )}
      </Box>
      <Box> {uploading && <Spinner />} </Box>

      {file && (
        <Box>
          <Typography variant="caption">{file.name}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadFile;
