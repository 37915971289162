import '../../libs/Modernizr';
/* eslint consistent-return: 0 */
/*
 * CSS transforms for IE10
 *
 * Inspiration:
 * https://github.com/thoughtbot/bourbon
 * /blob/master/app/assets/stylesheets/css3/_flex-box.scss
 *
 */
function prefixer(style, value) {
  let out = {};
  let key = window.Modernizr.prefixed(style);
  if (key) {
    out[key] = value;
  }
  return out;
}

function msStyle(style, value) {
  let out = {};
  if (window.Modernizr.testAllProps(style, value)) {
    out[style] = value;
  }
  return out;
}

// Custom shorthand notation for flexbox
export function box(orient = 'inline-axis', pack = 'start', align = 'stretch') {
  return Object.assign(
    {},
    displayBox(),
    boxOrient(orient),
    boxPack(pack),
    boxAlign(align),
  );
}

export function displayBox() {
  let out = {};
  [
    '-webkit-box',
    '-moz-box',
    '-ms-flexbox', // 2011 (IE 10)
    'box',
  ].forEach(val => {
    if (window.Modernizr.testAllProps('display', val)) {
      out.display = val;
    }
  });
  return out;
}

export function boxOrient(orient = 'inline-axis') {
  // horizontal|vertical|inline-axis|block-axis|inherit

  return Object.assign({}, prefixer('boxOrient', orient));
}

export function boxPack(pack = 'start') {
  // start|end|center|justify

  return Object.assign(
    {},
    prefixer('boxPack', pack),
    msStyle('msFlexPack', pack), // IE 10
  );
}

export function boxAlign(align = 'stretch') {
  // start|end|center|baseline|stretch

  return Object.assign(
    {},
    prefixer('boxAlign', align),
    msStyle('msFlexAlign', align), // IE 10
  );
}

export function boxDirection(direction = 'normal') {
  // normal|reverse|inherit

  return Object.assign(
    {},
    prefixer('boxDirection', direction),
    msStyle('msFlexDirection', direction), // IE 10
  );
}

export function boxLines(lines = 'single') {
  // single|multiple

  return Object.assign({}, prefixer('boxLines', lines));
}

export function boxOrdinalGroup(int = 1) {
  return Object.assign(
    {},
    prefixer('boxOrdinalGroup', int),
    msStyle('msFlexOrder', int), // IE 10
  );
}

export function boxFlex(value = 0) {
  return Object.assign(
    {},
    prefixer('boxFlex', value),
    msStyle('msFlex', value), // IE 10
  );
}

export function boxFlexGroup(int = 1) {
  return Object.assign({}, prefixer('boxFlexGroup', int));
}

export function display(value) {
  // flex | inline-flex
  let out = {};

  if (value === 'flex') {
    [
      // 2009
      '-webkit-box',
      '-moz-box',
      'box',
      // 2012
      '-webkit-flex',
      '-moz-flex',
      '-ms-flexbox', // 2011 (IE 10)
      'flex',
    ].forEach(val => {
      if (window.Modernizr.testAllProps('display', val)) {
        out.display = val;
      }
    });
  } else if (value === 'inline-flex') {
    [
      '-webkit-inline-box',
      '-moz-inline-box',
      'inline-box',
      '-webkit-inline-flex',
      '-moz-inline-flex',
      '-ms-inline-flexbox',
      'inline-flex',
    ].forEach(val => {
      if (window.Modernizr.testAllProps('display', val)) {
        out.display = val;
      }
    });
  }

  return out;
}

// 2009 - box-flex (integer)
// 2011 - flex (decimal | width decimal)
// 2012 - flex (integer integer width)
export function flex(value) {
  // 2009
  return Object.assign(
    {},
    prefixer('boxFlex', value[1]), // Grab flex-grow for older browsers
    prefixer('flex', value), // 2011 (IE 10), 2012
  );
}

// 2009 - box-orient ( horizontal | vertical | inline-axis | block-axis)
//      - box-direction (normal | reverse)
// 2011 - flex-direction (row | row-reverse | column | column-reverse)
// 2012 - flex-direction (row | row-reverse | column | column-reverse)
export function flexDirection(value = 'row') {
  // Alt values.
  let value2009 = value;
  let direction = 'normal';

  if (value === 'row') {
    value2009 = 'horizontal';
  } else if (value === 'row-reverse') {
    value2009 = 'horizontal';
    direction = 'reverse';
  } else if (value === 'column') {
    value2009 = 'vertical';
  } else if (value === 'column-reverse') {
    value2009 = 'vertical';
    direction = 'reverse';
  }

  return Object.assign(
    {},
    prefixer('boxOrient', value2009), // 2009
    prefixer('boxDirection', direction),
    prefixer('flexDirection', value), // 2012
    msStyle('msFlexDirection', value), // 2011 (IE 10)
  );
}

// 2009 - box-lines (single | multiple)
// 2011 - flex-wrap (nowrap | wrap | wrap-reverse)
// 2012 - flex-wrap (nowrap | wrap | wrap-reverse)
export function flexWrap(value = 'nowrap') {
  // Alt values
  let altValue = value;
  if (value === 'nowrap') {
    altValue = 'single';
  } else if (value === 'wrap') {
    altValue = 'multiple';
  } else if (value === 'wrap-reverse') {
    altValue = 'multiple';
  }

  return Object.assign(
    {},
    prefixer('boxLines', altValue), // 2009
    prefixer('flexWrap', value),
  );
}

// 2009 - TODO: parse values into flex-direction/flex-wrap
// 2011 - TODO: parse values into flex-direction/flex-wrap
// 2012 - flex-flow (flex-direction || flex-wrap)
export function flexFlow(value) {
  return Object.assign({}, prefixer('flexFlow', value));
}

// 2009 - box-ordinal-group (integer)
// 2011 - flex-order (integer)
// 2012 - order (integer)
export function order(int = 0) {
  return Object.assign(
    {},
    prefixer('boxOrdinalGroup', int), // 2009
    prefixer('order', int), // 2012
    msStyle('msFlexOrder', int), // 2011 (IE 10)
  );
}

// 2012 - flex-grow (number)
export function flexGrow(number = 0) {
  return Object.assign(
    {},
    prefixer('flexGrow', number),
    msStyle('msFlexPositive', number),
  );
}

// 2012 - flex-shrink (number)
export function flexShrink(number = 1) {
  return Object.assign(
    {},
    prefixer('flexShrink', number),
    msStyle('msFlexNegative', number),
  );
}

// 2012 - flex-basis (number)
export function flexBasis(width = 'auto') {
  return Object.assign(
    {},
    prefixer('flexBasis', width),
    msStyle('msFlexPreferredSize', width),
  );
}

// 2009 - box-pack (start | end | center | justify)
// 2011 - flex-pack (start | end | center | justify)
// 2012 - justify-content (flex-start | flex-end | center | space-between |
// space-around)
export function justifyContent(value = 'flex-start') {
  // Alt values.
  let altValue = value;
  if (value === 'flex-start') {
    altValue = 'start';
  } else if (value === 'flex-end') {
    altValue = 'end';
  } else if (value === 'space-between') {
    altValue = 'justify';
  } else if (value === 'space-around') {
    altValue = 'distribute';
  }

  return Object.assign(
    {},
    prefixer('boxPack', altValue), // 2009
    prefixer('justifyContent', value), // 2012
    msStyle('msFlexPack', altValue), // 2011 (IE 10)
  );
}

// 2009 - box-align (start | end | center | baseline | stretch)
// 2011 - flex-align (start | end | center | baseline | stretch)
// 2012 - align-items (flex-start | flex-end | center | baseline | stretch)
export function alignItems(value = 'stretch') {
  let altValue = value;

  if (value === 'flex-start') {
    altValue = 'start';
  } else if (value === 'flex-end') {
    altValue = 'end';
  }

  return Object.assign(
    {},
    prefixer('boxAlign', altValue), // 2009
    prefixer('alignItems', value), // 2012
    msStyle('msFlexAlign', altValue), // 2011 (IE 10)
  );
}

// 2011 - flex-item-align (auto | start | end | center | baseline | stretch)
// 2012 - align-self (auto | flex-start | flex-end | center | baseline |
// stretch)
export function alignSelf(value = 'auto') {
  let value2011 = value;
  if (value === 'flex-start') {
    value2011 = 'start';
  } else if (value === 'flex-end') {
    value2011 = 'end';
  }

  return Object.assign(
    {},
    prefixer('alignSelf', value), // 2012
    msStyle('msFlexItemAlign', value2011), // 2011 (IE 10)
  );
}
// 2011 - flex-line-pack (start | end | center | justify | distribute |
// stretch)
// 2012 - align-content (flex-start | flex-end | center | space-between |
// space-around | stretch)
export function alignContent(value = 'stretch') {
  let value2011 = value;
  if (value === 'flex-start') {
    value2011 = 'start';
  } else if (value === 'flex-end') {
    value2011 = 'end';
  } else if (value === 'space-between') {
    value2011 = 'justify';
  } else if (value === 'space-around') {
    value2011 = 'distribute';
  }

  return Object.assign(
    {},
    prefixer('alignContent', value), // 2012
    msStyle('msFlexLinePack', value2011), // 2011 (IE 10)
  );
}

export default function flexify(objIn) {
  let obj = Object.assign({}, objIn);

  // set the correct display value
  if (obj.hasOwnProperty('display')) {
    Object.assign(obj, display(obj.display));
  }

  if (obj.hasOwnProperty('boxOrient')) {
    Object.assign(obj, display(obj.boxOrient));
  }

  if (obj.hasOwnProperty('boxPack')) {
    Object.assign(obj, boxPack(obj.boxPack));
  }

  if (obj.hasOwnProperty('boxAlign')) {
    Object.assign(obj, boxAlign(obj.boxAlign));
  }

  if (obj.hasOwnProperty('boxDirection')) {
    Object.assign(obj, boxAlign(obj.boxDirection));
  }

  if (obj.hasOwnProperty('boxLines')) {
    Object.assign(obj, boxAlign(obj.boxLines));
  }

  if (obj.hasOwnProperty('boxOrdinalGroup')) {
    Object.assign(obj, boxOrdinalGroup(obj.boxOrdinalGroup));
  }

  if (obj.hasOwnProperty('boxFlex')) {
    Object.assign(obj, boxFlex(obj.boxFlex));
  }

  if (obj.hasOwnProperty('boxFlexGroup')) {
    Object.assign(obj, boxFlexGroup(obj.boxFlexGroup));
  }

  if (obj.hasOwnProperty('flexDirection')) {
    Object.assign(obj, flexDirection(obj.flexDirection));
  }

  if (obj.hasOwnProperty('flexWrap')) {
    Object.assign(obj, flexWrap(obj.flexWrap));
  }

  if (obj.hasOwnProperty('flexFlow')) {
    Object.assign(obj, flexFlow(obj.flexFlow));
  }

  if (obj.hasOwnProperty('order')) {
    Object.assign(obj, order(obj.order));
  }

  if (obj.hasOwnProperty('flex')) {
    Object.assign(obj, flex(obj.flex));
  }

  if (obj.hasOwnProperty('flexGrow')) {
    Object.assign(obj, flexGrow(obj.flexGrow));
  }

  if (obj.hasOwnProperty('flexShrink')) {
    Object.assign(obj, flexShrink(obj.flexShrink));
  }

  if (obj.hasOwnProperty('flexBasis')) {
    Object.assign(obj, flexBasis(obj.flexBasis));
  }

  if (obj.hasOwnProperty('flexDirection')) {
    Object.assign(obj, flexDirection(obj.flexDirection));
  }

  if (obj.hasOwnProperty('justifyContent')) {
    Object.assign(obj, justifyContent(obj.justifyContent));
  }

  if (obj.hasOwnProperty('alignItems')) {
    Object.assign(obj, alignItems(obj.alignItems));
  }

  if (obj.hasOwnProperty('alignItems')) {
    Object.assign(obj, alignItems(obj.alignItems));
  }

  if (obj.hasOwnProperty('alignSelf')) {
    Object.assign(obj, alignSelf(obj.alignSelf));
  }

  if (obj.hasOwnProperty('alignContent')) {
    Object.assign(obj, alignContent(obj.alignContent));
  }

  return { ...obj };
}
