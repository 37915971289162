import modularScale from 'modular-scale';

export const ms = modularScale({
  bases: [16, 25.888],
  ratios: [1.1414]
});
window.ms = ms;

// SES Blues
export const KULERS = ['#13393D', '#204C57', '#478594', '#6EA3B8', '#B2CED9'];

export const COLORS = {
  BACKGROUND: KULERS[0],
  TEXT: KULERS[4],
  TEXT_INPUT_BORDER: KULERS[2],
  TEXT_INPUT_TEXT: KULERS[3],
  TEXT_INPUT_LABEL: KULERS[2],
  BUTTON_BACKGROUND: KULERS[3],
  BUTTON_TEXT: KULERS[0],
  BRAND_TEXT: KULERS[0],
  NAVBAR_BACKGROUND: KULERS[3],
  NAVBAR_TEXT: KULERS[0],
  NAVBAR_ITEM_TEXT: KULERS[1],
};

export const shadow = '0 8px 20px 0 rgba(20, 0, 0, .3)';
export const shadowStyle = {
  WebkitBoxShadow: shadow,
  MozBoxShadow: shadow,
  boxShadow: shadow
};
