import { Switch, Redirect } from 'react-router-dom';
import { NavbarAuth } from './components/navbar/NavbarAuth';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { ClientDetails } from './components/clients/ClientDetails';
import { OrgDetails } from './components/orgs/OrgDetails';
import { EventDetails } from './components/events/EventDetails';
import { CarrierList } from './components/carriers/CarrierList';
import { CarrierDetails } from './components/carriers/CarrierDetails';
import { EventCarrierInfo } from './components/events/EventCarrierInfo';
import { EventOrgInfo } from './components/events/EventOrgInfo';
import { InspectData } from './components/inspect/InspectData';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { lazy, Suspense } from 'react';
import ErrorBoundary from './components/error/ErrorBoundary';
import { CarriersProvider } from './contexts/Carriers';

const ClientList = lazy(() => import('./components/clients/ClientList'));

export let AuthApp = () => (
  <Box
    sx={{
      flexGrow: 1,
      width: '100%',
      height: '100%',
      position: 'relative',
    }}
  >
    {/* Header */}
    <NavbarAuth />
    <Toolbar />
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 64px)',
        position: 'relative',
        p: 3,
      }}
    >
      {/* Canvas */}
      <CarriersProvider>
        <ErrorBoundary fallback={<div>Error</div>}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PrivateRoute exact path="/clients" component={ClientList} />
              <PrivateRoute
                exact
                path="/clients/:clientId"
                component={ClientDetails}
              />
              <PrivateRoute
                exact
                path="/clients/:clientId/orgs/:orgId"
                component={OrgDetails}
              />
              <PrivateRoute
                exact
                path="/clients/:clientId/events/:eventId"
                component={EventDetails}
              />
              <PrivateRoute
                exact
                path="/clients/:clientId/events/:eventId/carriers/:carrierId"
                component={EventCarrierInfo}
              />
              <PrivateRoute
                exact
                path="/clients/:clientId/events/:eventId/orgs/:orgId"
                component={EventOrgInfo}
              />
              <PrivateRoute exact path="/carriers" component={CarrierList} />
              <PrivateRoute
                exact
                path="/carriers/:carrierId"
                component={CarrierDetails}
              />
              <PrivateRoute exact path="/inspect" component={InspectData} />
              <Redirect to="/inspect" />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </CarriersProvider>
    </Box>
  </Box>
);
