import React, { useContext } from 'react';
import { TextInput } from '../shared/TextInput';
import { Button } from '../shared/Button';
import { PageTitle } from '../shared/PageTitle';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import { ms } from '../../constants';
import { FirebaseContext } from '../../contexts/firebase';

export const SignInBox = () => {
  let email = '';
  let password = '';

  const { auth } = useContext(FirebaseContext);

  const signIn = async () => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Flexbox
      style={{
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FlexColumn
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          margin: ms(4),
        }}
      >
        <PageTitle
          titleStyle={{ paddingTop: 0 }}
          style={{ marginBottom: 0 }}
          title="IHC Admin"
        />

        {/* Inputs */}
        <FlexColumn
          style={{
            padding: ms(5),
            justifyContent: 'flex-end',
            width: 400,
            boxSizing: 'border-box',
          }}
        >
          <TextInput label="Email" onChange={e => (email = e.target.value)} />

          <TextInput
            label="Password"
            type="password"
            onChange={e => (password = e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                signIn();
              }
            }}
          />
        </FlexColumn>

        <Button onClick={signIn}>Sign In</Button>
      </FlexColumn>
    </Flexbox>
  );
};
