import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  addContentMenu,
  addContentLanguage,
  updateContent,
  mapArrayToObject,
} from '../../actions/Events';
import { EditableTextInput } from '../shared/TextInput';
import { UploadFile } from '../shared/UploadFile';
import { useParams } from 'react-router';
import { UrlInfo } from './UrlInfo';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { OrgsContext } from '../../contexts/Orgs';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FirebaseContext } from '../../contexts/firebase';
import AddCardIcon from '@mui/icons-material/AddCard';
import { mapObjectArrayToArray } from '../../utils/objectArrays';
import { Menu as SubMenu } from './EventCarrierMenu';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Button } from '@mui/material';
import { httpsCallable } from 'firebase/functions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const langOptions = [
  { label: 'English', id: 'english' },
  { label: 'Spanish', id: 'spanish' },
];

const conditions = [
  { id: 'none', label: 'None' },
  { id: 'PLAN_HAS_PPO', label: 'PLAN_HAS_PPO' },
  { id: 'PLAN_HAS_EPO', label: 'PLAN_HAS_EPO' },
  { id: 'PLAN_HAS_HSA', label: 'PLAN_HAS_HSA' },
  { id: 'PLAN_HAS_ACO', label: 'PLAN_HAS_ACO' },
  { id: 'PLAN_HAS_HPN', label: 'PLAN_HAS_HPN' },
  { id: 'IS_PRIEST', label: 'IS_PRIEST' },
  { id: 'IS_RETIRED_PRIEST', label: 'IS_RETIRED_PRIEST' },
  { id: 'IS_SEMINARIAN', label: 'IS_SEMINARIAN' },
  { id: 'IS_LAY', label: 'IS_LAY' },
];

const ContentItem = ({
  contentId,
  index,
  language: lang,
  onUpdateContent,
  englishContent,
}) => {
  const { clientId, eventId, carrierId } = useParams();
  const { firebase, functions } = useContext(FirebaseContext);

  const orgs = useContext(OrgsContext);
  const [onlyOrgs, setOnlyOrgs] = useState([]);
  const [notOrgs, setNotOrgs] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (!lang.menus) {
      setSubMenus([]);
      return;
    }

    setSubMenus(
      mapObjectArrayToArray(lang.menus).map((menu, i) => ({
        ...menu,
        id: `sub-menu-${contentId}-${index}-${i}`,
      })),
    );
  }, [lang, contentId, index]);

  // map the orgs to an autocomplete list
  useEffect(() => {
    const forOrgs = lang.forOrgs && JSON.parse(lang.forOrgs);
    setOnlyOrgs(
      orgs?.map(org => {
        return {
          label: org.displayName,
          id: org.id,
          selected: forOrgs?.includes(org.id),
        };
      }),
    );

    const excludeOrgs = lang.excludeOrgs && JSON.parse(lang.excludeOrgs);
    setNotOrgs(
      orgs?.map(org => {
        return {
          label: org.displayName,
          id: org.id,
          selected: excludeOrgs?.includes(org.id),
        };
      }),
    );
  }, [orgs, lang.forOrgs, lang.excludeOrgs]);

  const handleDragEnd = event => {
    const { active, over } = event;
    console.log({ active, over });

    setSubMenus(menus => {
      const oldIndex = menus.findIndex(d => d.id === active.id);
      const newIndex = menus.findIndex(d => d.id === over.id);

      const newArray = arrayMove(menus, oldIndex, newIndex);

      onUpdateContent(clientId, eventId, carrierId, {
        [`content.${contentId}.${index}.menus`]: mapArrayToObject(
          newArray.map(d => {
            const { id, ...menu } = d;
            return menu;
          }),
        ),
      });

      return newArray;
    });
  };

  const onTranslate = async (val, updatePath) => {
    const translate = httpsCallable(functions, 'translate');
    const translations = await translate({
      english: val,
      language: lang.language,
    });

    await onUpdateContent(clientId, eventId, carrierId, {
      [updatePath]: translations.data[0],
    });
  };

  return (
    <Card variant="outlined" style={{ width: 400 }}>
      <Box direction="column" sx={{ p: 2, boxSizing: 'border-box' }}>
        <FormControl fullWidth variant="filled">
          <Stack spacing={2}>
            {/* Language */}
            <Autocomplete
              size="small"
              id="language"
              options={langOptions}
              renderInput={params => (
                <TextField {...params} variant="filled" label="Language" />
              )}
              value={langOptions.find(opt => opt.id === lang.language) || ''}
              onChange={(event, newValue) => {
                onUpdateContent(clientId, eventId, carrierId, {
                  [`content.${contentId}.${index}.language`]: newValue?.id,
                });
              }}
            />

            {/* Condition */}
            <Autocomplete
              size="small"
              id="condition"
              options={conditions}
              renderInput={params => (
                <TextField {...params} variant="filled" label="Condition" />
              )}
              value={
                conditions.find(cond => cond.id === lang.condition) ||
                conditions[0]
              }
              onChange={(event, newValue) => {
                console.log({ newValue });
                let val = newValue?.id;
                if (!val || val === 'none') {
                  val = firebase.database.FieldValue.delete();
                }
                onUpdateContent(clientId, eventId, carrierId, {
                  [`content.${contentId}.${index}.condition`]: val,
                });
              }}
            />

            {/* Only For Organizations */}
            <Stack>
              <CopyPaste field={`content.${contentId}.${index}.forOrgs`} />
              <Stack direction="row" width="100%">
                <Autocomplete
                  sx={{ flex: 1 }}
                  size="small"
                  multiple
                  disableCloseOnSelect
                  id="forOrgs"
                  options={onlyOrgs}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Only For Organizations"
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label} ({option.id})
                      </li>
                    );
                  }}
                  value={onlyOrgs.filter(org => org.selected)}
                  onChange={(event, newValue) => {
                    let val = newValue?.length
                      ? JSON.stringify(newValue.map(org => org.id))
                      : firebase.database.FieldValue.delete();

                    onUpdateContent(clientId, eventId, carrierId, {
                      [`content.${contentId}.${index}.forOrgs`]: val,
                    });
                  }}
                />
              </Stack>
            </Stack>

            {/* Exclude Orgs */}
            <Stack>
              <CopyPaste field={`content.${contentId}.${index}.excludeOrgs`} />
              <Stack direction="row" width="100%">
                <Autocomplete
                  sx={{ flex: 1 }}
                  size="small"
                  multiple
                  disableCloseOnSelect
                  id="notOrgs"
                  options={notOrgs}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Exclude Organizations"
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label} ({option.id})
                      </li>
                    );
                  }}
                  value={notOrgs.filter(org => org.selected)}
                  onChange={(event, newValue) => {
                    let val = newValue?.length
                      ? JSON.stringify(newValue.map(org => org.id))
                      : firebase.database.FieldValue.delete();

                    onUpdateContent(clientId, eventId, carrierId, {
                      [`content.${contentId}.${index}.excludeOrgs`]: val,
                    });
                  }}
                />
              </Stack>
            </Stack>

            {/* Title */}
            <Stack>
              <EditableTextInput
                label="Title"
                defaultValue={lang.title || ''}
                enablePreview
                onChange={val =>
                  onUpdateContent(clientId, eventId, carrierId, {
                    [`content.${contentId}.${index}.title`]: val,
                  })
                }
              />
              <Button
                variant="outlined"
                onClick={() =>
                  onTranslate(
                    englishContent.title,
                    `content.${contentId}.${index}.title`,
                  )
                }
                disabled={lang.language === 'english' || !lang.language}
                color="secondary"
              >
                Translate From English
              </Button>
            </Stack>

            {/* URL */}
            <Stack>
              <CopyPaste field={`content.${contentId}.${index}.url`} />
              <EditableTextInput
                label="Url"
                defaultValue={lang.url || ''}
                // enablePreview
                onChange={val =>
                  onUpdateContent(clientId, eventId, carrierId, {
                    [`content.${contentId}.${index}.url`]: val,
                  })
                }
              />

              <UrlInfo url={lang.url} />
            </Stack>
          </Stack>
        </FormControl>

        {/* Upload File */}
        <UploadFile
          index={`${contentId}-${index}`}
          dest={`/clients/${clientId}/events/${eventId}/carriers/${carrierId}/{basename}.{ext}`}
          onUploaded={url =>
            onUpdateContent(clientId, eventId, carrierId, {
              [`content.${contentId}.${index}.url`]: url,
            })
          }
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h6">Sub Menus</Typography>

          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              const newMenus = mapArrayToObject([
                ...subMenus.map((sm, i) => {
                  const { id, ...menu } = sm;
                  return menu;
                }),
                {},
              ]);
              onUpdateContent(clientId, eventId, carrierId, {
                [`content.${contentId}.${index}.menus`]: newMenus,
              });
            }}
          >
            <AddCardIcon />
          </IconButton>
        </Box>

        <Box>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <Stack spacing={2}>
              <SortableContext
                items={subMenus}
                strategy={verticalListSortingStrategy}
              >
                {subMenus.map((menu, i) => {
                  return (
                    <SubMenu
                      key={i}
                      contentId={contentId}
                      langId={index}
                      language={lang.language}
                      menuId={i}
                      menu={menu}
                    />
                  );
                })}
              </SortableContext>
            </Stack>
          </DndContext>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              onUpdateContent(clientId, eventId, carrierId, {
                [`content.${contentId}.${index}`]:
                  firebase.database.FieldValue.delete(),
              });
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export default connect(
  (state, ownProps) => {
    return {};
  },
  dispatch => ({
    onAddMenu: (...args) => dispatch(addContentMenu(...args)),
    onAddLanguage: (...args) => dispatch(addContentLanguage(...args)),
    onUpdateContent: (...args) => dispatch(updateContent(...args)),
  }),
)(ContentItem);

const deep_value = (o, p) => p.split('.').reduce((a, v) => a[v], o);

const CopyPaste = ({ field, isJSON = false }) => {
  const { clientId, eventId, carrierId } = useParams();
  const { database } = useContext(FirebaseContext);

  const copy = async () => {
    const doc = await database
      .doc(`clients/${clientId}/events/${eventId}/carriers/${carrierId}`)
      .get();
    let value = deep_value(doc.data(), field);
    if (isJSON) {
      value = JSON.stringify(value);
    }

    console.debug({ value, field });

    navigator.clipboard.writeText(value);
  };

  const paste = async () => {
    const text = await navigator.clipboard.readText();
    let data = text;
    if (isJSON) {
      data = JSON.parse(text);
    }

    console.debug({ data, field });

    await database
      .doc(`clients/${clientId}/events/${eventId}/carriers/${carrierId}`)
      .update(field, data);
  };

  return (
    <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'flex-end' }}>
      <IconButton onClick={copy} sx={{ flex: 0 }}>
        <ContentCopyIcon fontSize="small" color="primary" />
      </IconButton>

      <IconButton onClick={paste} sx={{ flex: 0 }}>
        <ContentPasteIcon fontSize="small" color="secondary" />
      </IconButton>
    </Stack>
  );
};
