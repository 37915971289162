import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { COLORS } from '../../constants';
import Color from 'color2';
import { DataGrid } from '@mui/x-data-grid';
import { FirebaseContext } from '../../contexts/firebase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import NewCarrierModal from './NewCarrierModal';
import { useHistory } from 'react-router-dom';
import { CarriersContext } from '../../contexts/Carriers';

export const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px;
  box-sizing: border-box;

  &:nth-child(odd) {
    background-color: ${new Color(COLORS.BACKGROUND).lighten(0.1)};
  }
`;

export const CarrierList = () => {
  const carriers = useContext(CarriersContext);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const cols = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'displayName', headerName: 'Display Name', flex: 1 },
    { field: 'tpaId', headerName: 'TPA ID', flex: 1 },
  ];

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: { xs: 'box', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2" sx={{ pr: 3 }}>
          Carriers
        </Typography>

        <Fab color="primary" size="small" aria-label="add">
          <AddIcon onClick={() => setModal(true)} />
        </Fab>

        <NewCarrierModal open={modal} onClose={handleCloseModal} />
      </Box>

      <Box sx={{ width: '100%', mt: 3 }}>
        <DataGrid
          rows={carriers}
          columns={cols}
          autoHeight={true}
          density="compact"
          onPageSizeChange={pageSize => console.log('pageSize', pageSize)}
          disableSelectionOnClick={true}
          onRowClick={row => {
            console.log('row', row);
            history.push(`/carriers/${row.id}`);
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
              '&:focus-within': {
                outline: 'none',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CarrierList;
