import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getEventDetails,
  getEventOrgInfo,
  updateOrg,
  addWhatsNew,
  addOtherPlan,
} from '../../actions/Events';
import { getOrgDetails, getOrgs } from '../../actions/Organizations';
import { Link } from 'react-router-dom';
import { Flexbox, FlexColumn } from '../shared/Flexbox';
import { WhatsNew } from './EventOrgWhatsNew';
import { EditableTextArea } from '../shared/TextInput';
import { useParams, withRouter } from 'react-router';
import _has from 'lodash.has';
import { OtherPlan } from './OtherPlan';
import _isPlainObject from 'lodash.isplainobject';
import { FirebaseContext } from '../../contexts/firebase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  walkObjArraysToObj,
  walkObjToObjArray,
} from '../../utils/objectArrays';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const _EventOrgInfo = ({ onUpdate, onAddWhatsNew, onAddOtherPlan }) => {
  const { database } = useContext(FirebaseContext);
  const { enqueueSnackbar } = useSnackbar();

  const { clientId, eventId, orgId } = useParams();

  const [allEvents, setAllEvents] = useState([]);
  const [fromEvent, setFromEvent] = useState({});
  const [copyTo, setCopyTo] = useState('');
  const [org, setOrg] = useState({});
  const [orgs, setOrgs] = useState([]);
  const [eventOrg, setEventOrg] = useState({});
  const [event, setEvent] = useState({});

  useEffect(() => {
    const unsubs = [
      database.doc(`/clients/${clientId}/events/${eventId}`).onSnapshot(doc => {
        setEvent({ id: doc.id, ...doc.data() });
      }),

      database
        .doc(`/clients/${clientId}/organizations/${orgId}`)
        .onSnapshot(doc => {
          setOrg({ id: doc.id, ...doc.data() });
        }),

      database.collection(`/clients/${clientId}/events`).onSnapshot(docs => {
        setAllEvents(
          docs.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          }),
        );
      }),

      database
        .collection(`/clients/${clientId}/organizations`)
        .onSnapshot(docs => {
          setOrgs(
            docs.docs.map(doc => {
              return { id: doc.id, ...doc.data() };
            }),
          );
        }),

      database
        .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
        .onSnapshot(doc => {
          setEventOrg({ id: doc.id, ...walkObjArraysToObj(doc.data()) });
        }),
    ];
    return () => unsubs.forEach(f => f());
  }, [clientId, database, eventId, orgId]);

  const handleAddWhatsNew = async () => {
    const whatsNew = eventOrg.whatsNew || [];
    whatsNew.push({});

    await database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .update({ whatsNew: walkObjToObjArray(whatsNew) });
  };

  const handleCopyWhatsNew = async () => {
    console.log('copy', clientId, orgId, eventId, copyTo);

    const from = await global.fb.database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .get();

    console.log('FROM', from.data().whatsNew);
    console.log(
      'TO',
      `/clients/${clientId}/events/${eventId}/organizations/${copyTo}`,
    );

    const docRef = await global.fb.database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${copyTo}`)
      .get();

    if (!docRef.exists) {
      const data = from.data();
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}/organizations/${copyTo}`)
        .set({ ...data, orgId: copyTo });
    } else {
      await global.fb.database
        .doc(`/clients/${clientId}/events/${eventId}/organizations/${copyTo}`)
        .update({ whatsNew: from.data().whatsNew });
    }
  };

  const handleDelete = async id => {
    const updatedWhatsNew = eventOrg.whatsNew.filter((_, i) => i !== id);

    await global.fb.database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .update({ whatsNew: updatedWhatsNew });
  };

  const handleCopyOthersFromEvent = async () => {
    const doc = await database
      .doc(`/clients/${clientId}/events/${fromEvent.id}/organizations/${orgId}`)
      .get();

    if (!doc.exists) {
      enqueueSnackbar(`${orgId} not found in ${fromEvent.id}.  Skipping.`, {
        variant: 'warning',
      });
    }

    const fromData = doc.data();

    if (!fromData.plans) {
      enqueueSnackbar(
        `No "others" found in ${fromEvent.id}/${orgId}.  Skipping.`,
        {
          variant: 'warning',
        },
      );
      return;
    }

    await database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .update('plans', fromData.plans);
  };

  const handleCopyWhatsNewFromEvent = async () => {
    if (!fromEvent.others) {
      enqueueSnackbar(`No "others" found in ${fromEvent.id}.  Skipping.`, {
        variant: 'warning',
      });
      return;
    }

    const doc = await database
      .doc(`/clients/${clientId}/events/${fromEvent.id}/organizations/${orgId}`)
      .get();

    if (!doc.exists) {
      enqueueSnackbar(`${orgId} not found in ${fromEvent.id}.  Skipping.`);
    }

    const fromData = doc.data();

    if (!fromData.whatsNew) {
      enqueueSnackbar(
        `No "whatsNew" found in ${fromEvent.id}/${orgId}.  Skipping.`,
        {
          variant: 'warning',
        },
      );
      return;
    }

    await database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .update('whatsNew', fromData.whatsNew);
  };

  const handleAddOtherBenefits = async () => {
    const other = eventOrg.plans?.available?.other || [];
    other.push({});

    await database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .update({ 'plans.available.other': walkObjToObjArray(other) });
  };

  const downloadJson = async () => {
    const doc = await database
      .doc(`/clients/${clientId}/events/${eventId}/organizations/${orgId}`)
      .get();

    let file;
    try {
      file = await window.showSaveFilePicker({
        suggestedName: `${eventId}.${orgId}.json`,
      });
    } catch (e) {
      console.error(e);
    }

    const blob = new Blob([JSON.stringify(doc.data(), null, 2)], {
      type: 'application/json',
    });

    const ws = await file.createWritable();
    await ws.write(blob);
    await ws.close();
  };

  return (
    <div
      style={{
        width: '100%',
        padding: 16,
        boxSizing: 'border-box',
      }}
    >
      <Stack
        direction="row"
        sx={{ width: '100%', justifyContent: 'space-betweenn' }}
      >
        <Stack direction="column" sx={{ flex: 1 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: 'baseline', flex: 1 }}
          >
            <Typography variant="h4">Event:</Typography>
            <Link to={`/clients/${clientId}/events/${eventId}`}>
              <Typography variant="h5">{event.displayName}</Typography>
            </Link>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
            <Typography variant="h4">Org:</Typography>
            <Link to={`/clients/${clientId}/organization/${orgId}`}>
              <Typography variant="h5">{org.displayName}</Typography>
            </Link>
          </Stack>
        </Stack>

        <Stack>
          <IconButton aria-label="download" onClick={downloadJson}>
            <CloudDownloadIcon />
          </IconButton>
        </Stack>
      </Stack>

      <EditableTextArea
        label="Message"
        defaultValue={
          eventOrg.message && JSON.parse(eventOrg.message).join('\n')
        }
        onChange={val =>
          onUpdate(clientId, eventId, orgId, {
            message: JSON.stringify(val.split('\n')),
          })
        }
      />

      <Box>
        <Typography variant="h6">Copy From Event</Typography>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={allEvents}
            sx={{ width: 400 }}
            renderInput={params => <TextField {...params} label="Events" />}
            onChange={(event, newValue) => {
              console.log({ newValue });
              setFromEvent(newValue);
            }}
            getOptionLabel={option => option.displayName}
          />
          <Button
            variant="contained"
            disabled={!fromEvent.id}
            onClick={handleCopyOthersFromEvent}
          >
            Copy "Other" Event Info
          </Button>
          <Button
            variant="contained"
            disabled={!fromEvent.id}
            onClick={handleCopyWhatsNewFromEvent}
          >
            Copy "What's New" Event Info
          </Button>
        </Stack>
      </Box>

      <Flexbox>
        <h2>Whats New</h2>
      </Flexbox>

      {/* Copy Event */}
      <div>
        <div>Copy to Organization:</div>
        <div>
          <select onChange={e => setCopyTo(e.target.value)}>
            <option>&nbsp;</option>
            {orgs
              .sort((a, b) =>
                a.displayName < b.displayName
                  ? -1
                  : a.displayName > b.displayName
                  ? 1
                  : 0,
              )
              .map((org, id) => (
                <option key={id} value={org.id}>
                  {org.displayName} ({org.id})
                </option>
              ))}
          </select>
        </div>
        <div>
          <button onClick={handleCopyWhatsNew}>Copy</button>
        </div>
      </div>

      <Box>
        <Stack spacing={2}>
          {eventOrg.whatsNew?.map((wn, i) => {
            if (!_isPlainObject(wn)) {
              return null;
            }

            return (
              <WhatsNew
                key={i}
                whatsNewId={i}
                whatsNew={wn}
                onDelete={() => handleDelete(i)}
              />
            );
          })}
          <Button onClick={handleAddWhatsNew} variant="outlined">
            Add What's New
          </Button>
        </Stack>
      </Box>

      <Flexbox>
        <h2>Other Plans</h2>
      </Flexbox>

      <FlexColumn
        style={{
          overflow: 'auto',
        }}
      >
        <Stack spacing={2}>
          {eventOrg.plans?.available?.other.map((p, i) => {
            console.log('--', { eventOrg });
            return <OtherPlan key={i} index={i} data={p} eventOrg={eventOrg} />;
          })}
          <Button onClick={handleAddOtherBenefits} variant="outlined">
            Add Other Plan
          </Button>
        </Stack>
      </FlexColumn>
    </div>
  );
};
export const EventOrgInfo = withRouter(
  connect(null, dispatch => ({
    onUpdate: (...args) => dispatch(updateOrg(...args)),
    onAddWhatsNew: (...args) => dispatch(addWhatsNew(...args)),
    onAddOtherPlan: (...args) => dispatch(addOtherPlan(...args)),
  }))(_EventOrgInfo),
);
