import { connect } from 'react-redux';
import { getOrgs } from '../../actions/Organizations';
import { useHistory, useParams } from 'react-router-dom';
import { NewOrg } from './NewOrg';
import { Label } from '../clients/ClientDetails';
import { Link, Id as _Id } from '../events/EventList';
import styled from 'styled-components/macro';
import { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import NewOrgModal from './NewOrgModal';
import { OrgsContext } from '../../contexts/Orgs';

const TpaId = styled(_Id)`
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Id = styled(_Id)`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;

const DisplayName = styled.div`
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OrgList = () => {
  const orgs = useContext(OrgsContext);
  const history = useHistory();
  const { clientId } = useParams();

  const cols = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'displayName', headerName: 'Display Name', flex: 1 },
    { field: 'tpaId', headerName: 'TPA Id', flex: 1 },
  ];

  const [modal, setModal] = useState(false);

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: { xs: 'box', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2" sx={{ pr: 3 }}>
          Organizations
        </Typography>

        <Fab color="primary" size="small" aria-label="add">
          <AddIcon onClick={() => setModal(true)} />
        </Fab>

        <NewOrgModal open={modal} onClose={handleCloseModal} />
      </Box>

      <Box sx={{ width: '100%', pt: 3 }}>
        <DataGrid
          rows={orgs}
          columns={cols}
          autoHeight={true}
          density="compact"
          disableSelectionOnClick={true}
          onRowClick={row => {
            console.log('row', row);
            history.push(`/clients/${clientId}/orgs/${row.id}`);
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
              outline: 'none !important',
            },
          }}
        />
      </Box>
    </Box>
  );
};
