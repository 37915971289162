import { Box } from '@mui/system';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Error boundaries currently have to be classes.
class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }
  render() {
    if (this.state.hasError) {
      return (
        <Box>
          <Box>{this.props.fallback}</Box>
          <Accordion expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Error Message</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="pre">{this.state.error.message}</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
