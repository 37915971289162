import React, { useContext, useEffect, useReducer } from 'react';
import { FirebaseContext } from './firebase';
import { useParams } from 'react-router-dom';

export const OrgsContext = React.createContext([]);

export const OrgsProvider = ({ children }) => {
  const { database } = useContext(FirebaseContext);
  const { clientId } = useParams();

  const [orgs, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'UPDATE':
        return action.orgs;
      default:
        return state;
    }
  }, []);

  /**
   * Get the list of orgs
   */
  useEffect(() => {
    if (!clientId) {
      console.warn('NO CLIENT ID');
      return;
    }

    const unsub = database
      .collection(`/clients/${clientId}/organizations`)
      .onSnapshot(docs => {
        const orgs = docs.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        dispatch({ type: 'UPDATE', orgs });
      });

    return unsub;
  }, [clientId, database]);

  return <OrgsContext.Provider value={orgs}>{children}</OrgsContext.Provider>;
};
