import { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { CarriersContext } from '../../contexts/Carriers';
import { OrgsContext } from '../../contexts/Orgs';
import { connect } from 'react-redux';
import {
  getEventDetails,
  getEventOrgs,
  getEventCarriers,
  deleteEvent,
  addOrgInfo,
  deleteOrgInfo,
  updateEventInfo,
} from '../../actions/Events';
import { getCarrierList } from '../../actions/Carriers';
import { getOrgs } from '../../actions/Organizations';
import { DeleteIcon } from '../shared/DeleteIcon';
import { Link } from 'react-router-dom';
import { EditableTextArea, Label } from '../shared/TextInput';
import { withRouter, useParams, useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { COLORS } from '../../constants';
import Color from 'color2';
import EventDetailsWhatsNew from './EventDetailsWhatsNew';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ErrorBoundary from '../error/ErrorBoundary';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  h2 {
    font-size: 1.75em;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:nth-child(odd) {
    background-color: ${new Color(COLORS.BACKGROUND).lighten(0.1)};
  }

  &:hover {
    border: 1px solid cyan;
  }

  & .join {
    display: flex;

    & * {
      padding-left: 6px;
    }
  }
`;

const _EventDetails = ({ onDeleteEvent, onUpdate }) => {
  const history = useHistory();
  const { clientId, eventId } = useParams();
  const firebase = useContext(FirebaseContext);
  const carriers = useContext(CarriersContext);

  const cols = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'displayName', headerName: 'Display Name', flex: 3 },
  ];
  const [eventCarriers, setEventCarriers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [event, setEvent] = useState({});
  const [eventOrgs, setEventOrgs] = useState([]);

  useEffect(() => {
    const fns = [
      firebase.database
        .doc(`/clients/${clientId}/events/${eventId}`)
        .onSnapshot(doc => {
          console.log({ doc });
          setEvent({
            id: doc.id,
            ...doc.data(),
          });
        }),

      firebase.database
        .collection(`/clients/${clientId}/events/${eventId}/carriers`)
        .onSnapshot(docs => {
          setEventCarriers(
            docs.docs.map(doc => {
              const { displayName } = doc.data();
              return {
                id: doc.id,
                displayName,
              };
            }),
          );
        }),

      firebase.database
        .collection(`/clients/${clientId}/organizations`)
        .onSnapshot(docs => {
          setOrgs(
            docs.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }),
          );
        }),

      firebase.database
        .collection(`/clients/${clientId}/events/${eventId}/organizations`)
        .onSnapshot(docs => {
          setEventOrgs(
            docs.docs.map(doc => {
              return {
                id: doc.id,
              };
            }),
          );
        }),
    ];

    return () => fns.forEach(f => f());
  }, [clientId, eventId, firebase.database]);

  const [carrierRows, setCarrierRows] = useState([]);
  const [availableCarriers, setAvailableCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  useEffect(() => {
    const carriersById = carriers.reduce((acc, carrier) => {
      acc[carrier.id] = carrier;
      return acc;
    }, {});

    setCarrierRows(
      eventCarriers
        .map(carrier => carriersById[carrier.id])
        .filter(carrier => carrier !== undefined),
    );

    const carriersWithEvents = eventCarriers.map(carrier => carrier.id);
    setAvailableCarriers(
      carriers
        .filter(carrier => !carriersWithEvents.includes(carrier.id))
        .map(carrier => ({
          label: `${carrier.displayName} (${carrier.id})`,
          id: carrier.id,
        })),
    );
  }, [carriers, eventCarriers]);

  /**
   * Orgs
   */
  const [orgRows, setOrgRows] = useState([]);
  const [availableOrgs, setAvailableOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    console.log({ eventOrgs, orgs });
    const orgsById = orgs.reduce((acc, org) => {
      acc[org.id] = org;
      return acc;
    }, {});

    setOrgRows(
      eventOrgs.map(org => orgsById[org.id]).filter(org => org !== undefined),
    );

    const orgsWithEvents = eventOrgs.map(org => org.id);
    setAvailableOrgs(
      orgs
        .filter(org => !orgsWithEvents.includes(org.id))
        .map(org => ({
          label: `${org.displayName} (${org.id})`,
          id: org.id,
        })),
    );
  }, [orgs, eventOrgs]);

  return (
    <Box>
      <h1>Event:{event.displayName}</h1>
      <DeleteIcon onClick={() => onDeleteEvent(clientId, eventId, history)} />
      <Box>
        <EditableTextArea
          label="Message"
          defaultValue={event.message && JSON.parse(event.message).join('\n')}
          onChange={val =>
            onUpdate(clientId, eventId, {
              message: JSON.stringify(val.split('\n')),
            })
          }
        />
      </Box>
      <Box>
        <div>
          <Label>Disable Congratulations Message</Label>
          <input
            type="checkbox"
            onChange={e =>
              onUpdate(clientId, eventId, { disableCongrats: e.target.checked })
            }
          />
        </div>
      </Box>
      <Box>
        <EditableTextArea
          label="Congratulations"
          defaultValue={event.congrats && JSON.parse(event.congrats).join('\n')}
          onChange={val =>
            onUpdate(clientId, eventId, {
              congrats: JSON.stringify(val.split('\n')),
            })
          }
        />
      </Box>
      {/* What's New */}
      <Box>
        <EventDetailsWhatsNew />
      </Box>
      <Box
        sx={{
          display: { xs: 'box', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2" sx={{ pr: 3 }}>
          Carriers
        </Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={availableCarriers}
            sx={{ width: 400 }}
            renderInput={params => (
              <TextField {...params} label="Available Carriers" />
            )}
            onChange={(event, newValue) => {
              console.log({ newValue });
              setSelectedCarrier(newValue);
            }}
          />
          <Button
            variant="contained"
            disabled={!selectedCarrier}
            onClick={async () => {
              console.log({ selectedCarrier });
              const path = `/clients/${clientId}/events/${eventId}/carriers/${selectedCarrier.id}`;
              await firebase.database.doc(path).set({ id: selectedCarrier.id });
              history.push(path);
            }}
          >
            Add Event Info
          </Button>
        </Stack>
      </Box>
      <Box sx={{ width: '100%', mt: 3 }}>
        <ErrorBoundary fallback={<div>Error with carrier list</div>}>
          <DataGrid
            rows={carrierRows}
            columns={cols}
            autoHeight={true}
            density="compact"
            disableSelectionOnClick={true}
            pageSize={25}
            onRowClick={async row => {
              history.push(
                `/clients/${clientId}/events/${eventId}/carriers/${row.id}`,
              );
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                cursor: 'pointer',
                outline: 'none !important',
              },
            }}
          />
        </ErrorBoundary>
      </Box>
      <Typography variant="h2" sx={{ pr: 3 }}>
        Organizations
      </Typography>
      <Box>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={availableOrgs}
            sx={{ width: 400 }}
            renderInput={params => (
              <TextField {...params} label="Available Orgs" />
            )}
            onChange={(event, newValue) => {
              console.log({ newValue });
              setSelectedOrg(newValue);
            }}
          />
          <Button
            variant="contained"
            disabled={!selectedOrg}
            onClick={async () => {
              await firebase.database
                .doc(
                  `/clients/${clientId}/events/${eventId}/organizations/${selectedOrg.id}`,
                )
                .set({ id: selectedOrg.id });
              history.push(
                `/clients/${clientId}/events/${eventId}/orgs/${selectedOrg.id}`,
              );
            }}
          >
            Add Event Info
          </Button>
        </Stack>
      </Box>
      <Box sx={{ width: '100%', mt: 3 }}>
        <ErrorBoundary fallback={<div>Error with org list</div>}>
          {console.log({ orgRows })}
          <DataGrid
            rows={orgRows}
            columns={cols}
            autoHeight={true}
            density="compact"
            disableSelectionOnClick={true}
            pageSize={25}
            onRowClick={async row => {
              history.push(
                `/clients/${clientId}/events/${eventId}/orgs/${row.id}`,
              );
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                cursor: 'pointer',
                outline: 'none !important',
              },
            }}
          />
        </ErrorBoundary>
      </Box>
      })}
    </Box>
  );
};

export const EventDetails = withRouter(
  connect(null, dispatch => ({
    onUpdate: (...args) => dispatch(updateEventInfo(...args)),
  }))(_EventDetails),
);
