import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addSectionItem, updateContent } from '../../actions/Events';
import { EditableTextInput } from '../shared/TextInput';
import Button from '@mui/material/Button';
import { useParams, withRouter } from 'react-router';
import { FirebaseContext } from '../../contexts/firebase';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Stack from '@mui/material/Stack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  mapArrayToObject,
  mapObjectArrayToArray,
} from '../../utils/objectArrays';
import Item from './VideoItem';

let Section = ({
  section,
  contentId,
  sectionId,
  onAddItem,
  onUpdateContent,
}) => {
  const { clientId, eventId, carrierId } = useParams();
  const { database } = useContext(FirebaseContext);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `section-${contentId}-${sectionId}`,
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(section.items);
  }, [section.items]);

  const handleDragEnd = event => {
    const { active, over } = event;
    console.log({ active, over });

    setItems(items => {
      const oldIndex = items.findIndex(c => c.id === active.id);
      const newIndex = items.findIndex(c => c.id === over.id);

      const newArray = arrayMove(items, oldIndex, newIndex);

      onUpdateContent(clientId, eventId, carrierId, {
        [`videoContent.${contentId}.sections.${sectionId}.items`]:
          mapArrayToObject(newArray.map(i => i.item)),
      });

      return newArray;
    });
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      ref={setNodeRef}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
        width: '100%',
        zIndex: isDragging ? 1000 : 'auto',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton
              {...listeners}
              {...attributes}
              aria-label="drag"
              size="small"
            >
              <DragIndicatorIcon color="primary" fontSize="inherit" />
            </IconButton>

            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2">{section.title}</Typography>
              {/* <Typography variant="caption">({language})</Typography> */}
            </Box>
          </Box>

          <IconButton
            aria-label="drag"
            size="small"
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon color="secondary" />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={() => setAnchorEl(null)}
        >
          <MenuList dense>
            <MenuItem
              onClick={async () => {
                const ref = database.doc(
                  `/clients/${clientId}/events/${eventId}/carriers/${carrierId}`,
                );
                const doc = await ref.get();
                const data = doc.data();
                const sections = mapObjectArrayToArray(
                  data.videoContent[contentId].sections,
                ).filter((s, i) => i !== sectionId);

                await ref.update({
                  [`videoContent.${contentId}.sections`]:
                    mapArrayToObject(sections),
                });
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </AccordionSummary>

      <EditableTextInput
        label="Title"
        defaultValue={section.title}
        onChange={val =>
          onUpdateContent({
            [`videoContent.${contentId}.sections.${sectionId}.title`]: val,
          })
        }
      />

      <div>Items</div>

      <Box>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={section.items}
            strategy={verticalListSortingStrategy}
          >
            <Stack spacing={1}>
              {items.map((item, i) => {
                console.log('=>>', i, item);
                return (
                  <Item
                    key={i}
                    contentId={contentId}
                    sectionId={sectionId}
                    itemId={i}
                    item={item.item}
                  />
                );
              })}
            </Stack>
          </SortableContext>
        </DndContext>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={event => {
            event.stopPropagation();
            const newItems = mapArrayToObject([
              ...items.map(i => {
                const { id, ...item } = i;

                return item.item;
              }),
              {},
            ]);

            onUpdateContent(clientId, eventId, carrierId, {
              [`videoContent.${contentId}.sections.${sectionId}.items`]:
                newItems,
            });
          }}
        >
          Add Item
        </Button>
      </Box>
    </Accordion>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        clientId: ownProps.match.params.clientId,
        eventId: ownProps.match.params.eventId,
        carrierId: ownProps.match.params.carrierId,
        ...ownProps,
      };
    },
    dispatch => ({
      onAddItem: (...args) => dispatch(addSectionItem(...args)),
      onUpdateContent: (...args) => dispatch(updateContent(...args)),
    }),
    null,
    { areOwnPropsEqual: () => false },
  )(Section),
);
