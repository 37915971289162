import React from 'react';
import { ms } from '../../constants';

export const Title = props => (
  <div
    style={{
      paddingTop: ms(4),
      paddingBottom: ms(4),
      fontFamily: 'Barlow Semi Condensed',
      fontSize: ms(6),
      lineHeight: 1.3,
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      ...props.style,
    }}
  >
    {props.children}
  </div>
);
