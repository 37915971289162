import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getCarrierDetails,
  updateCarrierDetails,
  newContact,
  updateContact,
  deleteContact,
  deleteCarrier,
} from '../../actions/Carriers';
import { EditableTextInput, EditableTextArea } from '../shared/TextInput';
import { ms } from '../../constants';
import { Button } from '../shared/Button';
import { DeleteIcon } from '../shared/DeleteIcon';
import { UploadImage } from '../shared/UploadImage';
import { Checkbox } from '../shared/Checkbox';
import _has from 'lodash.has';
import { Flexbox } from '../shared/Flexbox';
import styled from 'styled-components/macro';
import Stack from '@mui/material/Stack';
import SlugEditor from '../shared/SlugEditor';

const _CarrierDetails = ({
  carrier,
  onGetCarrierDetails,
  onUpdateContact,
  onUpdateDetails,
  onDeleteCarrier,
  onNewContact,
  onDeleteContact,
}) => {
  const contacts = useState(carrier.contacts);
  const { carrierId } = useParams();
  const history = useHistory();

  useEffect(() => {
    return onGetCarrierDetails(carrierId);
  }, [carrierId, onGetCarrierDetails]);

  function _onUpdate(obj) {
    return onUpdateDetails(carrierId, obj);
  }

  function _onUpdateContact(id, field, val) {
    return onUpdateContact(carrierId, id, field, val);
  }

  return (
    <div
      style={{
        width: '100%',
        padding: ms(-1),
      }}
    >
      <h4>Carrier:</h4>
      <h1>{carrier.displayName}</h1>
      <DeleteIcon onClick={() => onDeleteCarrier(carrierId, history)} />

      <div
        style={{
          padding: ms(-1),
          width: '100%',
          minWidth: 350,
          boxSizing: 'border-box',
        }}
      >
        <EditableTextInput
          label="tpaId"
          defaultValue={carrier.tpaId}
          onChange={val => _onUpdate({ tpaId: val })}
        />

        <EditableTextInput
          label="Display Name"
          defaultValue={carrier.displayName}
          onChange={val => _onUpdate({ displayName: val })}
        />

        <EditableTextInput
          label="Corporate Website"
          defaultValue={carrier.corpSite}
          onChange={val => _onUpdate({ corpSite: val })}
        />

        <h2>Contact</h2>

        <Flexbox
          style={{
            overflow: 'auto',
          }}
        >
          {contacts &&
            Object.keys(carrier.contacts).map(i => {
              const c = carrier.contacts[i];

              return (
                <div
                  key={i}
                  style={{
                    flex: '0 0 550px',
                    border: '1px solid #275965',
                    margin: 16,
                    padding: 16,
                    boxSizing: 'border-box',
                  }}
                >
                  <DeleteIcon onClick={() => onDeleteContact(carrierId, i)} />

                  <EditableTextArea
                    label="Description"
                    defaultValue={
                      c.description && JSON.parse(c.description).join('\n')
                    }
                    onChange={val =>
                      _onUpdateContact(
                        i,
                        'description',
                        JSON.stringify(val.split('\n')),
                      )
                    }
                  />

                  <EditableTextInput
                    label="Call"
                    defaultValue={c.call}
                    onChange={val => _onUpdateContact(i, 'call', val)}
                  />

                  <EditableTextArea
                    label="Phones"
                    defaultValue={c.phones && JSON.parse(c.phones).join('\n')}
                    onChange={val =>
                      _onUpdateContact(
                        i,
                        'phones',
                        JSON.stringify(val.split('\n')),
                      )
                    }
                  />

                  <EditableTextInput
                    label="Email"
                    defaultValue={c.email}
                    onChange={val => _onUpdateContact(i, 'email', val)}
                  />

                  <EditableTextInput
                    label="Website Display Name"
                    defaultValue={c.website && c.website.name}
                    onChange={val => _onUpdateContact(i, 'website.name', val)}
                  />

                  <EditableTextInput
                    label="Website Url"
                    defaultValue={c.website && c.website.url}
                    onChange={val => _onUpdateContact(i, 'website.url', val)}
                  />

                  <EditableTextArea
                    label="Claims Address"
                    defaultValue={
                      c.claimsAddress && JSON.parse(c.claimsAddress).join('\n')
                    }
                    onChange={val =>
                      _onUpdateContact(
                        i,
                        'claimsAddress',
                        JSON.stringify(val.split('\n')),
                      )
                    }
                  />

                  <EditableTextArea
                    label="Raw"
                    defaultValue={c.raw}
                    onChange={val => _onUpdateContact(i, 'raw', val)}
                  />
                </div>
              );
            })}
        </Flexbox>

        <Button onClick={() => onNewContact()}>Add Additonal Contact</Button>

        <Flexbox>
          <EditableTextInput
            label="Menu Background Color"
            defaultValue={
              _has(carrier, 'colors.background') &&
              carrier.colors.background.menu
            }
            onChange={val => _onUpdate({ 'colors.background.menu': val })}
          />

          <EditableTextInput
            label="Side Background Color"
            defaultValue={
              _has(carrier, 'colors.background') &&
              carrier.colors.background.side
            }
            onChange={val => _onUpdate({ 'colors.background.side': val })}
          />
        </Flexbox>
      </div>

      <div>
        <h2>Images</h2>

        <UploadImage
          title="Background"
          obj={carrier}
          objPath="images.background.url"
          dest={`/carriers/${carrierId}/images/originals/background.{epoch}.{ext}`}
          metadata={{
            resize: '800>',
            gsOutputPath: `/carriers/${carrierId}/images/background.{epoch}.{ext}`,
            firestorePath: `/carriers/${carrierId}`,
            firestoreField: 'images.background.url',
          }}
          onUploaded={origUrl => {
            // update the database record
            _onUpdate({ 'images.background.origUrl': origUrl });
          }}
        />

        <Stack direction="row" spacing={1}>
          <EditableTextInput
            label="Position X"
            defaultValue={
              carrier.images &&
              carrier.images.background &&
              carrier.images.background.position &&
              carrier.images.background.position.x
            }
            onChange={val =>
              _onUpdate({
                'images.background.position.x': val,
              })
            }
          />
          <EditableTextInput
            label="Position Y"
            defaultValue={
              carrier.images &&
              carrier.images.background &&
              carrier.images.background.position &&
              carrier.images.background.position.y
            }
            onChange={val =>
              _onUpdate({
                'images.background.position.y': val,
              })
            }
          />

          <EditableTextInput
            label="Min Height"
            defaultValue={
              carrier.images &&
              carrier.images.background &&
              carrier.images.background.minHeight
            }
            onChange={val =>
              _onUpdate({
                'images.background.minHeight': val,
              })
            }
          />
        </Stack>

        <UploadImage
          title="Logo"
          obj={carrier}
          objPath="images.logo.url"
          dest={`/carriers/${carrierId}/images/originals/logo.{epoch}.{ext}`}
          metadata={{
            resize: '250x100>',
            gsOutputPath: `/carriers/${carrierId}/images/logo.{epoch}.{ext}`,
            firestorePath: `/carriers/${carrierId}`,
            firestoreField: 'images.logo.url',
          }}
          onUploaded={origUrl => {
            // update the database record
            _onUpdate({ 'images.logo.origUrl': origUrl });
          }}
        />

        <SlugEditor slug={carrier.slug} carrierId={carrierId} />

        <Checkbox
          label="Use Original"
          defaultChecked={
            carrier.images &&
            carrier.images.logo &&
            carrier.images.logo.useOriginal
          }
          handleInputChange={checked =>
            _onUpdate({ 'images.logo.useOriginal': checked })
          }
        />

        <Stack direction="row" spacing={1}>
          <EditableTextInput
            label="Width"
            defaultValue={
              carrier.images && carrier.images.logo && carrier.images.logo.width
            }
            onChange={val => _onUpdate({ 'images.logo.width': parseInt(val) })}
          />
          <EditableTextInput
            label="Height"
            defaultValue={
              carrier.images &&
              carrier.images.logo &&
              carrier.images.logo.height
            }
            onChange={val => _onUpdate({ 'images.logo.height': parseInt(val) })}
          />
        </Stack>

        <Stack spacing={2}>
          <UploadImage
            title="Small Logo"
            obj={carrier}
            objPath="images.logoSmall.url"
            dest={`/carriers/${carrierId}/images/originals/logoSmall.{epoch}.{ext}`}
            metadata={{
              resize: '150x40>',
              gsOutputPath: `/carriers/${carrierId}/images/logoSmall.{epoch}.{ext}`,
              firestorePath: `/carriers/${carrierId}`,
              firestoreField: 'images.logoSmall.url',
            }}
            onUploaded={origUrl => {
              // update the database record
              _onUpdate({ 'images.logoSmall.origUrl': origUrl });
            }}
          />

          <Stack direction="row" spacing={1}>
            <EditableTextInput
              label="Width"
              defaultValue={
                carrier.images &&
                carrier.images.smallLogo &&
                carrier.images.logoSmall.width
              }
              onChange={val =>
                _onUpdate({ 'images.logoSmall.width': parseInt(val) })
              }
            />
            <EditableTextInput
              label="Height"
              defaultValue={
                carrier.images &&
                carrier.images.smallLogo &&
                carrier.images.logoSmall.height
              }
              onChange={val =>
                _onUpdate({ 'images.logoSmall.height': parseInt(val) })
              }
            />
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export const CarrierDetails = connect(
  state => ({
    carrier: state.Carriers.current,
  }),
  dispatch => ({
    onGetCarrierDetails: (...args) => dispatch(getCarrierDetails(...args)),
    onUpdateDetails: (...args) => dispatch(updateCarrierDetails(...args)),
    onDeleteCarrier: (...args) => dispatch(deleteCarrier(...args)),

    onNewContact: () => dispatch(newContact()),
    onUpdateContact: (...args) => dispatch(updateContact(...args)),
    onDeleteContact: (...args) => dispatch(deleteContact(...args)),
  }),
)(_CarrierDetails);
