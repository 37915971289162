import { SignInBox } from './components/auth/SignInBox';

export const UnauthApp = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
    }}
  >
    <SignInBox />
  </div>
);
