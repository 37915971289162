import { useEffect, useState } from 'react';
import { ms, COLORS } from '../../constants';
import { FlexColumn } from '../shared/Flexbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from 'react-hook-form';

export const Label = props => (
  <div
    style={{
      color: COLORS.TEXT_INPUT_LABEL,
      fontSize: ms(2),
      width: '100%',
      marginBottom: ms(1),
    }}
  >
    {props.children}
  </div>
);

export const Input = props => (
  <input
    {...props}
    style={{
      border: 'none',
      borderBottom: `1px solid ${COLORS.TEXT_INPUT_BORDER}`,
      backgroundColor: 'rgba(0,0,0,0)',
      color: COLORS.TEXT_INPUT_TEXT,
      outline: 'none',
      fontSize: ms(3),
      paddingBottom: ms(1),
      width: '100%',
      ...props.style,
    }}
  />
);

export const TextInput = props => (
  <FlexColumn
    style={{
      alignItems: 'baseline',
      paddingBottom: ms(3),
      ...props.style,
    }}
  >
    <Label style={props.labelStyle}>{props.label}</Label>
    <Input
      style={props.inputStyle}
      type={props.type || 'text'}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
    />
  </FlexColumn>
);

export const EditableTextInput = ({
  label,
  defaultValue = '',
  enablePreview = false,
  onChange = () => {},
  multiline = false,
  rows = 1,
  ...props
}) => {
  const [preview, setPreview] = useState(false);

  const { control, getValues, setValue, reset, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  });

  // Update the form if the defaultValue changes
  useEffect(() => {
    setValue('value', defaultValue);
  }, [defaultValue, setValue]);

  const value = watch('value');

  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CardContent sx={{ p: 0 }}>
        {/* Input */}
        <Controller
          name="value"
          control={control}
          rules={{ required: true }}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => {
            return (
              <TextField
                {...field}
                label={label}
                multiline={multiline}
                rows={rows}
                variant="filled"
                sx={{
                  width: '100%',
                }}
              />
            );
          }}
        />

        {/* Preview */}
        {enablePreview && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="Preview"
                sx={{ '& .MuiFormControlLabel-label': { fontSize: 12 } }}
                onChange={e => setPreview(e.target.checked)}
              />
            </Box>
            {preview && <div dangerouslySetInnerHTML={{ __html: value }} />}
          </Box>
        )}
      </CardContent>

      {formState.isDirty && (
        <CardActions>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              aria-label="cancel"
              onClick={() => reset({ value: defaultValue })}
            >
              <HighlightOffIcon color="error" />
            </IconButton>

            <IconButton
              aria-label="ok"
              onClick={() => {
                onChange(getValues().value);
                reset({ value: defaultValue });
              }}
            >
              <CheckCircleOutlineIcon color="success" />
            </IconButton>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
};

export const EditableTextArea = props => (
  <EditableTextInput {...props} multiline rows={4} />
);
